
export const SITE_SETTINGS='api/site-settings'
export const GET_SPORTS= 'v1/sports/management/getSport';
export const LOGOUT_API='api/logout';
export const AUTH_STATUS='api/auth-status';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const AUTH_REFRESH='api/auth-refresh';
export const WALLET_BALANCE='wallet';
export const SEARCH='api/search';
export const GET_WALLET_GETWAY='api/get-payment-getway-iframe-url';
export const SUBSCRIBE_NOTIFICATION= 'api/subscribe-notification';
export const NOTIFICATIONS_LIST= 'get/all/notification/history';
export const GET_SITE_RULES= 'api/get-site-rules';
export const GET_LOCKED_EARNED_BONUS = 'api/get-locked-earn-bonus';
export const GET_BONUS_LOGS= 'api/get-bonus-log';
export const UPDATE_WALLET_TYPE = 'api/wallet-switch';
export const  GET_MY_MARKETS='api/get-my-markets';
export const getVersionStatus= 'api/site-status-version';
export const v1_SITE_SETTINGS= 'api/v1-site-settings';
export const DREAM_CASINO='api/dreamcasino/load-game/';


