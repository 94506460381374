<template>
    <div v-if="siteSettings">
        <div class="container-fluid">
            <div class="last-end-section">
                <div class="betfair">
                    <ul class="cmn-ul-list">
                        <li>
                            <a href="#install-apk-model" data-bs-toggle="modal">
                                <img class="gc-logo" src="@/assets/images/download-apk.svg" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="siteSettings.user_journey" class="tutorials-btns">
                <ul class="cmn-ul-list">
                    <li>
                        <a
                            @click="chooseUserJourney(siteSettings.user_journey.introduction_video, 'Introduction')">Introduction</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.register_video, 'How to Register')">How to
                            register</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.deposit_video_e, 'How to Deposit')">How to
                            deposit</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.use_bonus_video, 'How to use bonus')">How to
                            use bonus</a>
                    </li>
                </ul>
            </div>
            <section class="help-and-support-box" v-if="$route.name=='Home'">
                <div class="whatapp-btn-fixed whatsApp" v-if="siteSettings && siteSettings?.business_type == 2 && checkIsLogin">
                    <a href="javascript:void(0);" @click="getParentWhatsappNumber()" data-bs-toggle="modal" data-bs-target="#whatsup-depositModal" target="_blank">
                        <img loading="lazy" src="@/assets/images/wp_support.webp" alt="">
                    </a>
                </div>
            </section>

            <!-- <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent> -->
            <!--Whatsup Deposit Modal -->
            <div class="modal fade whatsup-modal-wrapper" id="whatsup-depositModal" tabindex="-1" aria-labelledby="whatsupModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">{{translatedLangData('customer-support','Customer Support')}}</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body whatsup-modal-information">
                                <div class="whatsaap-support-btn-list" v-if="whatsappNumbers && whatsappNumbers.length > 0">
                                    <div class="support-call-now" v-for="(data, index) in whatsappNumbers" :key="index">
                                        <a :href="'https://wa.me/' + data.whatsapp_number" target="_blank">
                                            <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                            <span class="btn thm-btn">{{ data.category_name }}</span>
                                        </a>
                                    </div>
                                </div>
                                <div v-else-if="siteSettings && (siteSettings?.social_link?.whatsappno1 || siteSettings?.social_link?.whatsappno2)">
                                <template v-for="number in [siteSettings?.social_link?.whatsappno1, siteSettings?.social_link?.whatsappno2]">
                                    <!-- <a v-if="number"
                                        :key="number"
                                        :href="'https://wa.me/' + number"
                                        target="_blank"
                                        class="btn thm-btn call-now">
                                        <img src="@/assets/images/whatsApp-icon-modal.webp" alt="whatsapp">{{ number }}
                                    </a> -->
                                    <div class="support-call-now demologon-suport" >
                                    <a v-if="number" :key="number" :href="'https://wa.me/' + number" target="_blank" class="btn thm-btn call-now">
                                            <div class="support-whatsapp-icon"><img src="@/assets/images/whatsapp-icon-new.svg" alt="whatsapp"></div>
                                            <span class="btn thm-btn">{{ number }}</span>
                                        </a>
                                    </div>
                                </template>
                            </div>
                            <div v-else>
                                <p>{{translatedLangData('no-whatsapp-avail','No WhatsApp numbers available.')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <!--Whatsup Deposit Modal -->
            <div v-if="siteSettings?.social_link" class="mobile-social-icon">
                <div class="footer-social-bx">
                    <div v-if="siteSettings?.social_link?.whatsappno1" class="f-social-icon">
                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"><img
                                src="@/assets/images/whatsapp-icon.webp" alt="" /></a>
                    </div>


                    <div v-if="siteSettings?.social_link?.telegram_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.telegram_link" target="_blank"><img
                                src="@/assets/images/telegram.webp" alt="" /></a>
                    </div>
                    <div v-if="siteSettings?.social_link?.instagram_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.instagram_link" target="_blank"><img
                                src="@/assets/images/instagram.webp" alt="" /></a>
                    </div>

                    <div v-if="siteSettings?.social_link?.facebook_link"  class="f-social-icon">
                        <a :href="siteSettings?.social_link?.facebook_link" target="_blank">
                            <img loading="lazy" src="@/assets/images/facebook-icon.webp" alt="facebook" />
                        </a>
                    </div>

                </div>
            </div>
            <div class="gc-companies-sec">
                <div class="gc-companies-img"><a><img loading="lazy" src="@/assets/images/gc-logo.webp" alt="gaming"></a></div>
                <p class="gc-companies-info">LOTUS 399 is a limited liability company incorporated under the laws of Curacao
                    Players are requested not to contact any untrusted sources for LOTUS 399 accounts as this is an online
                    site
                    and they can only register independently without any agents. Only deposit through the account details
                    generated by the system or provided by our official support team.</p>
            </div>
            <div v-if="siteSettings?.business_type == 2" class="payment-methode-sec">
                <ul class="cmn-ul-list">
                    <li><a ><img loading="lazy" src="@/assets/images/payment-1.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-2.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-3.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-4.png" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-5.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-6.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-7.png" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-8.webp" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-9.png" alt=""></a></li>
                    <li><a ><img loading="lazy" src="@/assets/images/payment-11.webp" alt=""></a></li>
                </ul>
            </div>
        </div>
        <div class="sub-footer">
            <div class="container-fluid">
                <div v-if="siteSettings?.social_link"  class="footer-social-bx">
                    <div v-if="siteSettings?.social_link?.whatsappno1" class="f-social-icon">
                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank">
                            <img src="@/assets/images/whatsapp-icon.webp" alt="whatsapp" /></a>
                    </div>

                    <div v-if="siteSettings?.social_link?.youtube_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.youtube_link" target="_blank">
                            <img loading="lazy" src="@/assets/images/youtub-icon.webp" alt="youtub" />
                        </a>
                    </div>

                    <div v-if="siteSettings?.social_link?.telegram_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.telegram_link" target="_blank"><img
                                src="@/assets/images/telegram.webp" alt="" /></a>
                    </div>
                    <div v-if="siteSettings?.social_link?.instagram_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.instagram_link" target="_blank"><img
                                src="@/assets/images/instagram.webp" alt="" /></a>
                    </div>

                    <div v-if="siteSettings?.social_link?.facebook_link" class="f-social-icon">
                        <a :href="siteSettings?.social_link?.facebook_link" target="_blank">
                            <img loading="lazy" src="@/assets/images/facebook-icon.webp" alt="facebook" />
                        </a>
                    </div>
                </div>
                <div class="copyrights-bx">
                    <p><router-link :to="{ name: 'policies', params: { type: 'RulesRegulations' } }" >Rules Regulations</router-link> 2016-2022 v. 9.66</p>
                </div>
            </div>
        </div>

        <div class="whatapp-chat-icons" v-if="siteSettings && siteSettings?.business_type == 2 && !checkIsLogin">
            <div class="whatapp-btn-fixed whatsApp">
                <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"
                    v-if="siteSettings?.social_link && siteSettings?.social_link?.whatsappno1">
                    <img loading="lazy" src="@/assets/images/whats-app.webp" alt="">
                    <div :class="currentClass" class="circleIconPanel"></div>
                </a>
            </div>

        </div>
        <div class="chat-btn-fixed" v-if="$route.name == 'Home'">
            <ChatComponent @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc"></ChatComponent>
                <!-- <a href="#chatboat-modal" data-bs-toggle="modal"><img loading="lazy" src="@/assets/images/chat-icon.webp" alt=""></a> -->
        </div>
    </div>

    <div class="modal fade game-play-modal" @close="showUserJourneyVideo = false" @click="showUserJourneyVideo = false"
        id="show_user_journey_video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <VideoModal v-if="showUserJourneyVideo" :title="choosedUserJourney ? choosedUserJourney.title : null"
                :link="choosedUserJourney ? choosedUserJourney.link : null" />
        </div>
    </div>

    <div ref="show_user_journey_video" style="display: none;" data-bs-toggle="modal"
        data-bs-target="#show_user_journey_video">
    </div>

    <div class="top-scroll" v-if="$route.name!='matka-details'">
        <div class="container">
            <button v-if="showButton" @click="scrollToTop"><i class="fa-solid fa-angle-up"></i></button>
        </div>
    </div>
    <div class="top-scroll myMarket"  v-if="$route.name=='Home' || $route.name=='sports' || $route.name=='sports-event-detail' || $route.name=='MultiMarket'" >
        <div class="container">
            <button data-bs-toggle="modal" data-bs-target="#exampleModal" @click="callForMyMarket()"><i class="fa fa-eye"></i></button>
        </div>
    </div>
    <div class="gameon-btn" @click="getMiniGame">
        <a href="#"><img src="@/assets/images/promot.gif"></a>
    </div>

    <div class="game_lobby_iframe" v-if="isShowing" ref="game_lobby_iframe" >
        <span class="lobby-iframe-close-icon" @click="isShowing = false"><img src="@/assets/images/cross-icon.webp"></span>
        <iframe :src="iFrameUrl"></iframe>
    </div>
<DownloadApkModal></DownloadApkModal>
<MyMarket ref="myMarket" />

</template>
<script>

import VideoModal from '@/shared/components/modal/VideoModal.vue';
import ChatModal from './ChatModal.vue';
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';
import ChatComponent from '@/modules/chat/views/ChatComponent.vue';
import axios from 'axios';
import api from '@/shared/services/api.js';
import MyMarket from '../modal/MyMarket.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import * as apiName from '@/shared/services/urls';


export default {
    name: 'Footer',
    data() {
        return {
            classes: ['hideMessage', 'showMessage'],
            currentClassIndex: 0,
            hide: false,
            choosedUserJourney: { link: null, title: null },
            showButton: false,
            showUserJourneyVideo: false,
            isShowing:false,
            opencasinoiframeurl : null,
            whatsappNumbers:[],
            mobileDevice: $(window).width() <= 768 ? true : false,
            miniGameActive: false,
            iFrameUrl: null,
        }
    },
    inject:['translatedLangData'],
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        currentClass() {
            return this.classes[this.currentClassIndex];
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        }

    },
    components: {
    VideoModal,
    ChatModal,
    DownloadApkModal,
    ChatComponent,
    MyMarket,
},

mounted() {
    // Add a scroll event listener
    window.addEventListener("scroll", this.handleScroll);
    setInterval(() => {
      this.currentClassIndex = (this.currentClassIndex + 1) % this.classes.length;
    }, 3000);

    // if(this.mobileDevice != undefined && this.mobileDevice && this.opencasinoiframeurl == null) {
    //         this.loadShowGameOnIframe('dream', 'YG-MLOBBY');
    //     }
  }, 

  beforeDestroy() {
    // Remove the scroll event listener to prevent memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
    methods: {
        async getMiniGame() {
            if (!this.checkIsLogin) {
                this.isShowing = false;                
                return this.showErrorModalFunc('Please Login For Mini Games');
            }
            this.isShowing = true;
            const miniGameData = this.$store.getters.gamesData.sections.find(gm => gm.slug === 'yuvi-games')?.games[0];
            if (!miniGameData) {
                this.showErrorModalFunc('Mini Game Data Not Found.');
                this.closeMiniGame();
                return;
            }
                const headers = {
                    'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                };
                try {
                    const response = await api.post(`${apiName.DREAM_CASINO}${miniGameData.game_code}/${miniGameData.sm_id}`, {}, { headers });
                    if (response?.status === 200 && response?.data?.data?.url) {
                        this.iFrameUrl = response.data.data.url;
                    } else {
                        this.showErrorModalFunc('URL Not Found.');
                        this.closeMiniGame();
                    }
                } catch (error) {
                    this.showErrorModalFunc(error?.response?.data?.message || 'An error occurred.');
                    this.closeMiniGame();
                }
            },

        closeMiniGame() {
            this.isShowing = false;
        },

        callForMyMarket() {
            this.$refs.myMarket.getMyMarketsServiceCall('sport');
        },
        loadShowGameOnIframe(gtype, gamecode) {
            if(gtype == 'dream') {
                var platform    =   "GPL_MOBILE";
                var url         =   "/dreamcasino/load-game/" + gamecode + "?platform=" + platform;
                api.post(url).then((res) => {
                    if (res.data.error != null) {
                    } else {
                        this.opencasinoiframeurl    =   res.data.url;
                    }
                })
                .catch((e) => {
                    
                });
            }
        },
        showGameOnIframe() {
            if(this.opencasinoiframeurl == null) {
                this.loadShowGameOnIframe('dream', 'YG-MLOBBY');
            }
            if(this.opencasinoiframeurl != null) {
                document.querySelector('.game_lobby_iframe').style.display = 'block';
            }
        },
        closeGameOnIframe() {
            document.querySelector('.game_lobby_iframe').style.display = 'none';
        },
        closeGameOnIframeEvent(event) {
            /*const game_lobby_iframe = this.$refs.game_lobby_iframe;
            if (!game_lobby_iframe.contains(event.target)) {
                alert("asdasd");
                document.querySelector('.game_lobby_iframe').style.display = 'none';
            }*/
        },
    async getParentWhatsappNumber() {
            if (this.checkIsLogin) {
                this.loading = true;
                this.$store.dispatch('setGlobalLoader',true)
                try {
                    const cpiExists = this.$store.getters.stateUser?.cpi;
                    const supportNumbers = this.siteSettings.support_numbers || [];
                    if (!cpiExists) {
                        return;
                    }

                    const now = new Date();
                    const totalMinutes = now.getHours() * 60 + now.getMinutes();

                    this.whatsappNumbers = supportNumbers
                        .filter(entry => entry.id === cpiExists)
                        .flatMap(entry => entry.categories.map(category => {
                            const whatsappNumbers = category.value.split(',');
                            const selectedNumberIndex = totalMinutes % whatsappNumbers.length;
                            return {
                                category_name: category.name,
                                whatsapp_number: whatsappNumbers[selectedNumberIndex]
                            };
                        }));
                    this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                } catch (error) {
                    this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                    this.showErrorModalFunc(error[0]);
                }
            }
        },
        handleScroll() {
            // Check the scroll position
            if (window.scrollY >= 50) {
                this.showButton = true;
            } else {
                this.showButton = false;
            }
            },
            scrollToTop() {
            // Scroll to the top of the page
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings.aws_url + this.siteSettings.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            //this.$bvModal.show('user_journey');
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();

        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}

</script>