const StatementsModule = () => import(/* webpackChunkName: "statements-module" */ './views/Module.vue');
const Statement = () => import(/* webpackChunkName: "statement" */ './views/Statement.vue');


const StatementRoutes = {
    path: '/',
    component: StatementsModule,
    children: [
        {
            path: 'statement/:type',
            name: 'statement',
            component: Statement,
        }
    ],
}

export default StatementRoutes;