<template>
   <div class="exposure">
      <div class="modal fade mymarket-modal-box" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-lg">
            <div class="modal-content">
               <!-- <div class="modal-header">
                  <h5 id="exampleModalLabel">
                     {{translatedLangData('my-market', 'My Market')}}
                     <span class="re-lode" @click="getMyMarketsServiceCall('sport')">
                        <i class="fa-sharp fa-solid fa-rotate-right"></i>
                     </span>
                  </h5>
               </div> -->
               <button type="button" class="cross-icon" data-bs-dismiss="modal" aria-label="Close"
                     ref="closeMyMarket">
                     <i class="fa fa-close"></i>
                  </button>
               <div class="modal-body">
                  <div class="my-market-exp-table account-statement-tbl">
                     <div class="table-responsive">
                        <table class="table b-table table-striped table-bordered bg-light">
                           <thead class="table-dark">
                              <tr class="theme1font">
                                 <th style="min-width: 90px;">{{translatedLangData('event-type', 'Event Type')}}</th>
                                 <th style="text-align: center;">{{translatedLangData('event-name', 'Event Name')}}</th>
                                 <!-- <th>Match Name</th> -->
                                 <th>{{translatedLangData('expose', 'Expose')}}</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(market, mIndex) in marketList" :key="mIndex" class="details-td-data">
                                 <td>{{ getEventType(market.sport_id) }}</td>
                                 <td class="event-txt-color" style="text-align: left;">
                                    <a href="Javascript:void(0);"
                                       @click="goToEventDetail(market)">
                                       {{ market.event_name }}
                                    </a>
                                 </td>
                                 <!-- <td>{{ market.market_name ?? '-' }}</td> -->
                                 <td :class="{'text-danger': market.exposure !== 0}"> {{ Math.abs(market.exposure) }}</td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { AUTH_TOKEN } from "@/shared/constants/cookies-const";

export default {
   name: "MyMarket",
   inject: ['translatedLangData'],
   components: {

   },
   mounted() {
      let sportList = this.$store.getters.sports_list;
      if (sportList) {
         this.sports = [...sportList['custom'], ...sportList['non_custom']];
      }
   },
   data() {
      return {
         marketList: [],
         loading: false,
         selectedSportItem: null,
         sports: null
      }
   },
   methods: {
      getMyMarketsServiceCall(type) {
         let authToken = this.$cookies.get(AUTH_TOKEN);
         if (!authToken) return;
         let headers = {
            'Authorization': `Bearer ${authToken}`
         }
         this.loading = true;
         this.$store.dispatch('setGlobalLoader',true)
         api.get(apiName.GET_MY_MARKETS + '/' + type, { headers }).then(response => {
            this.$store.dispatch('setGlobalLoader', false);
            this.loading = false;
            this.marketList = [];
            if (response) {
               if (response.status == 200) {
                  if (response?.data?.status == 0) {
                     this.showErrorModalFunc(response.data.debug[0]);
                  }
                  else {
                     //this.showSuccessModalFunc(response.data.message);
                     this.marketList = response.data.data;
                  }
               }
            }
         }).catch(error => {
            this.loading = false;
            this.$store.dispatch('setGlobalLoader',false)
            if (error) {
               this.showErrorModalFunc(error[0]);
            }
         });
      },
      getMatkaItem(item) {
         let mtkaItem =
         {
            "id": item?.worli_matka_id,
            "title": item?.event_name,
            "slug": item?.match_id,
            "open_time": item?.open_bids,
            "close_time": item?.close_bids,
         }

         return mtkaItem;
      },
      clickOnBetModal(sportItem) {
         this.selectedSportItem = sportItem;

      },
      close() {
         this.selectedSportItem = null;
      },
      showErrorModalFunc(message) {
         this.$emit('error-modal', message);
      },
      showSuccessModalFunc(message) {
         this.$emit('success-modal', message);
      },
      getEventType(id) {
         let name = '';

         if (id) {
            this.sports.forEach(item => {
               if (item.id == id) {
                  name = item.name
               }
            });
         }
         return name

      },
      goToEventDetail(market) {
         this.$router.push({ name: 'sports-event-detail', params: { event_id: market.event_id } });
         this.$refs.closeMyMarket.click();
      }

   }
}
</script>

<style></style>