
const Matka = () => import(/* webpackChunkName: "matka" */ './views/Matka.vue');
const MatkaModule = () => import(/* webpackChunkName: "matka-module" */ './views/Module.vue');
const MatkaDetails = () => import(/* webpackChunkName: "matka-details" */ './views/MatkaDetails.vue');

const MatkaRoutes =
{
    path: '/',
    component: MatkaModule,
    children: [
        {
            path: 'matka',
            name: 'matka',
            component: Matka,
        },
        {
            // path: 'matka-details/:id',
            path: 'matka-details/:itemDetail',
            name: 'matka-details',
            component: MatkaDetails,
        }
    ],
}

export default MatkaRoutes;