<template>
    <div class="modal fade autosearch-modal-box" id="autosearcheader" tabindex="-1"
        aria-labelledby="autosearcheaderLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="back-arrow-left" data-bs-dismiss="modal" @click="toggleMobileSearch">
                        <span class="beakto-search">
                            <i class="fa fa-arrow-left"></i>
                        </span>
                    </div>
                    <div class="input-box">
                        <input v-model="searchText" @input="searchCasinoMatches" type="text"
                        :placeholder="translatedLangData('search-events','Search Events')" />
                    </div>
                    <i @click="clearSearch" class="fa-solid fa-xmark close-icon"></i>
                </div>
                <div class="modal-body" v-if="searchResult != null && (searchResult.length != 0)">
                    <div v-for="(sport, s_index) in searchResult">
                        <div class="ip-lft-text" @click="openSportEventDetailPage(sport)">
                            <a href="Javascript:void(0);">{{ sport.event_name }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import { Modal } from 'bootstrap';
export default {
    name: 'SearchModal',
    inject: ['translatedLangData'],
    data() {
        return {
            searchText: '',
            searchResult: null,
            searchLoading: false,
        }
    },
    methods: {
        clearSearch() {
        this.searchText = '';
        this.searchResult = null;
        },
        openSportEventDetailPage(match) {
            this.searchResult = null;
            const modalElement = document.getElementById('autosearcheader');
            const modalInstance = Modal.getInstance(modalElement) || new Modal(modalElement);
            modalInstance.hide();
            this.searchText = "";
            this.$router.push({
                name: 'sports-event-detail',
                params: { event_id: match.event_id }
            });
        },
        toggleMobileSearch() {
            this.mobileSearch = !this.mobileSearch;
            this.searchText = "";
            this.searchResult = null;
        },
        searchCasinoMatches() {
            if (this.searchLoading || this.searchText === '' || this.searchText.length <= 2) {
                this.searchResult = null;
                return;
            }
            this.searchLoading = true;
            const headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            };
            const data = {
                search: this.searchText
            };
            api.post(apiName.SEARCH, data, { headers })
                .then(response => {
                    this.searchLoading = false;
                    if (response?.status === 200) {
                        this.searchResult = response.data.data?.matches || [];
                    } else {
                        this.showErrorModalFunc(response.data.debug[0]);
                    }
                })
                .catch(error => {
                    this.searchLoading = false;
                    this.showErrorModalFunc(error[0]);
                });
        },
    }
}
</script>