import { createApp } from 'vue'
import App from './App.vue'

import router from "./router"
import store from "./store";
import VueCookies from 'vue3-cookies';
//import "./assets/css/owl.carousel.min.css";
import "./assets/css/bootstrap.min.css";
//import "./assets/css/bootstrap.min.css.map";
import "./assets/css/style1.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/fontawesome/css/all.css";
import "bootstrap";
import 'vue3-carousel/dist/carousel.css';
import "vue-gtranslate/translator.css";
import "vue-awesome-paginate/dist/style.css";
import VueAwesomePaginate from "vue-awesome-paginate";
import './registerServiceWorker'

window.$ = window.jQuery = require('jquery');

router.beforeEach((to, from, next) => {
    if (document.querySelector('.modal.show')) {
        next(false);
        return;
    }
    const underMaintenance = store.getters.underMaintenance;
    const isMaintenancePage = to.name === 'Maintenance';
    if (underMaintenance) {
        if (!isMaintenancePage) {
            next({ name: 'Maintenance' });
            return;
        }
    } else {
        if (isMaintenancePage) {
            next({ name: 'Home' });
            return;
        }
    }
    if (window.store?.getters.isAuthenticated && 
        (!window.store?.getters?.stateUser?.last_login_at) && 
        to?.name !== 'change-password') {
        window.router.push({ name: 'change-password' });
        return;
    }
    next();
});

window.addEventListener('popstate', (event) => {
    if(document.querySelector('.modal.show')) {
        window.location.reload();
    }
});
const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueCookies);
app.use(VueAwesomePaginate);
app.mount("#app");

app.config.globalProperties.fantasy_server = "fantasycricket";
app.config.globalProperties.click_server = "fantasy";
