<template>
    <div class="sidebar-menu">
        <ul>
            <div class="offset-close" @click="$emit('close-mobile-sidebar')">
                <i class="fa fa-times"></i>
            </div>
            <li class="details-item cmn-ac-info">
                <strong class="ac-user-name">{{ userData?.name ?? userData?.userid }}</strong>
                <div class="dw-bets-sec">
                    <ul class="cmn-ul-list" v-if="siteSettings && siteSettings.business_type == 2">
                        <li class="betslip-btn">
                            <router-link :to="{ name: 'wallet-gateway' }" class="btn btn-primary withdraw-btn" @click="$emit('close-mobile-sidebar')">
                                <img loading="lazy" src="@/assets/images/deposit-icon.png" alt="walletW">{{translatedLangData('deposit','Deposit')}}
                            </router-link>                            
                        </li>
                        <li class="betslip-btn">
                            <router-link :to="{ name: 'wallet-gateway' }" class="btn btn-primary deposit-btn" @click="$emit('close-mobile-sidebar')">
                                <img loading="lazy" src="@/assets/images/withdrawal-icon.png" alt="walletW">{{translatedLangData('withdrawal','Withdraw')}}
                            </router-link>                            
                        </li>
                    </ul>
                </div>
            </li>
            
            <li>
                <div class="setting-user-checkbox">
                    <div class="user-details-r">
                        <label class="form-check-label" for="flexSwitchCheckDefault" >{{translatedLangData('one-click-bet', 'One Click Bet')}}</label>
                        <div class="form-check form-switch">
                            <input class="form-check-input" id="flexSwitchCheckDefault" type="checkbox" v-model="clickBetCheck"
                                @change="betCheckChangeFunc()">
                        </div>
                        <div v-if="clickBetCheck && getClickBetSelectedValue()"
                            @click="$refs.open_click_bet_value.click()">
                            <label>{{ getClickBetSelectedValue() }}</label>
                        </div>
                    </div>
                    <a style="display: none;" ref="open_click_bet_value" data-bs-toggle="modal"
                        data-bs-target="#clickBetValues"></a>
                </div>
            </li>
            <!-- <li class="details-item cmn-ac-info">
                <div class="details-link ac-nav-link">
                    <i class="fa-solid fa-building-columns"></i>
                    <span class="menu-title">Balance Information</span>
                </div>
                <div class="right-side-menu-account-info">
                    <div class="ac-info-item">
                        <span class="ac-info-label">Balance :</span>
                        <span class="ac-info-value">{{ userData?.balance }}</span>
                    </div>
                    <div  class="ac-info-item">
                        <span class="ac-info-label">Net Exposure:</span>
                        <span class="ac-info-value" :class="userData?.exposure>0?'net-exposure-bi':''">{{ userData?.exposure }}</span>
                    </div>
                </div>
            </li> -->
            <li class="details-item cmn-ac-info bonus-information-bxz">
                <!-- <div class="details-link ac-nav-link">
                    <span class="menu-title">Bonus Information</span>
                    <button type="button" class="btn link-btn" data-bs-toggle="modal"
                        data-bs-target="#bonus-wallet-modal">i</button>
                </div> -->
                <div class="right-side-menu-account-info">
                    <div class="ac-info-item">
                        <span class="ac-info-label">{{translatedLangData('balance', 'Balance ')}} </span>
                        <span>:</span>
                        <span class="ac-info-value">{{ userData?.balance }}</span>
                    </div>
                    <div class="ac-info-item">
                        <span class="ac-info-label">{{translatedLangData('net-exposure', 'Net Exposure ')}}</span>
                        <span>:</span>
                        <span class="ac-info-value" :class="userData?.exposure>0?'net-exposure-bi':''">{{ userData?.exposure!=null ? -parseInt(userData?.exposure) : 0 }}</span>
                    </div>
                    <div class="ac-info-item" v-if="siteSettings && siteSettings.business_type == 2">
                        <span class="ac-info-label">{{translatedLangData('earned-bonus', 'Earned Bonus')}}</span>
                        <span>:</span>
                        <span class="ac-info-value"  :class="lockedEarnedData?.earnBonus>0?'net-exposure-bi':''" >{{ lockedEarnedData?.earnBonus }}</span>
                    </div>
                    <div class="playwith__bonus_menu" v-if="siteSettings && siteSettings?.business_type == 2">
                        <div class="ac-info-item">
                            <span class="ac-info-label">{{translatedLangData('welcome-bonus', 'Welcome Bonus')}}</span>
                            <span class="ac-info-value">{{ lockedEarnedData?.welcomeBonus }}</span>
                        </div>
                        <div class="ac-info-item play-with-bonus">
                            <span class="ac-info-label">{{translatedLangData('play-with-bonus', 'Play with Bonus')}}</span>
                            <span class="ac-info-value">
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox"
                                    id="flexSwitchCheckDefault" v-model="getWalletType" @change="updateWalletType">
                                </div>
                            </span>
                        </div>
                    </div>
                    <div class="ac-info-item bonus-sidebar-btn" v-if="siteSettings && siteSettings?.business_type == 2">
                        <button class="btn thm-btn" @click="openBonusLogs()">{{translatedLangData('locked-bonus', 'Locked Bonus')}} <span>{{ lockedEarnedData?.lockedBonus }}</span></button>
                        <button class="btn thm-btn border-line-outer" @click="$emit('close-mobile-sidebar'); $router.push({ name: 'refer-earn' })">{{translatedLangData('refer-and-earn', 'REFER AND EARN')}}</button>
                    </div>
                    
                </div>
            </li>          
            <li class="details-item" v-if="siteSettings && siteSettings?.business_type == 2">
                <div @click="getRules(true)" data-bs-toggle="modal" data-bs-target="#informationModal">
                    <a href="Javascript:void(0);"  class="details-link ac-nav-link" data-bs-toggle="modal" data-bs-target="#informationModal">
                        <i class="fa fa-file-text"></i>
                        <span class="menu-title">{{translatedLangData('bonus-rules', 'Bonus Rules')}}</span>
                    </a>
                </div>
            </li>
            <li class="details-item">
                <router-link @click="$emit('close-mobile-sidebar')" :to="'/bets'" class="details-link ac-nav-link" >
                    <i class="fa fa-bars"></i>
                    <span class="menu-title">{{translatedLangData('open-bets', 'Open Bets')}}</span>
                </router-link>
            </li>

            <li class="details-item">
                <router-link @click="$emit('close-mobile-sidebar')" :to="'/bet-history'" class="details-link ac-nav-link">
                    <i class="fa fa-file-text"></i>
                    <span class="menu-title">{{translatedLangData('bet-history', 'Bet-History')}}</span>
                </router-link>
            </li>

            <li class="details-item">
                <router-link :to="'/win-loss'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa fa-question-circle"></i>
                    <span class="menu-title">{{translatedLangData('betting-profit-loss', 'Betting Profit & Loss')}}</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/statement/ACCOUNT'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa fa-cog"></i>
                    <span class="menu-title">{{translatedLangData('account-statement', 'Account Statement')}}</span>
                </router-link>
            </li>
            <!-- <li class="details-item">
                <router-link :to="'/open-bets'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-tags"></i>
                    <span class="menu-title">Bonus Statement</span>
                </router-link>
            </li> -->
            <li class="details-item">
                <router-link :to="'/change-password'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa fa-lock"></i>
                    <span class="menu-title">{{translatedLangData('change-password', 'Change Password')}}</span>
                </router-link>
            </li>
            <li class="details-item">
                    <a @click="getRules()"  class="details-link ac-nav-link">
                        <i class="fa fa-file-text"></i>
                        <span class="menu-title">{{translatedLangData('rules-and-regulations', 'Rules & Regulations')}}</span>
                    </a>
            </li>
            <li class="details-item">
                <router-link :to="'/setting'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-gear"></i>
                    <span class="menu-title">{{translatedLangData('setting', 'Settings')}}</span>
                </router-link>
            </li>
            <li class="details-item">
                <a class="details-link ac-nav-link" @click="logoutBtnClick();$emit('close-mobile-sidebar')">
                    <i class="fa-solid fa-right-from-bracket" ></i>
                    <span class="menu-title">{{translatedLangData('sign-out', 'Sign Out')}}</span>
                </a>
            </li>
        </ul>
    </div>
    
    <ClickBetModal @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @cancelOneClick = "clickBetCheck = false" />
    <div class="modal fade modal-footer-sticky" ref="siteRulesModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <RulesModal :siteRuleData='siteRuleData' />
    </div>
    <BonusInformation :siteRuleData="siteRuleData"/>
    <LockedBonus v-if="showBonusLogs" @success-modal="showSuccessModalFunc" @error-modal="showErrorModalFunc" @closeBonusLogs="closeBonusLogs()" />

</template>
<script>
import BonusInformation from '@/shared/components/header/BonusInfoModal.vue';
import RulesModal from './RulesModal.vue';
import LockedBonus from '@/shared/components/header/LockedBonus.vue';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { getWalletGetwayServiceCall } from '@/shared/services/wallet-service';
import captchaApi from '@/modules/authorization/services/captcha-api';
import { mapGetters } from 'vuex';
import ClickBetModal from '@/shared/components/modal/ClickBetModal.vue';
import { Modal } from 'bootstrap';

export default {
    name: 'MobileSideBar',
    inject:['translatedLangData'],
    data() {
        return {  
            clickBetCheck: this.$store?.getters?.isClickBetOn ? this.$store?.getters?.isClickBetOn : false,
            isLogin: null,
            userData: null,
            lockedEarnedData: null,
            showBonusLogs: false,
            getWalletType:false,
            siteRuleData: null,
            showBonusLogs: false,
            siteRuleModal: null,
        }
    },
    components:{
        ClickBetModal,
        BonusInformation,
        LockedBonus,
        RulesModal,
    },
    mounted(){
        this.isLogin = this.$store?.getters?.isAuthenticated;
        this.userData = this.$store?.getters?.stateUser;
        if(this.checkIsLogin() && this.siteSettings?.business_type == 2) {
            this.getLockedEarnedBonus();
            this.getWalletType = this.stateUser?.activeWallet == 'WB';
        } ;
        this.siteRulesModal();
    },
    computed:{
        ...mapGetters([
            'siteSettings', 'stateUser', 'getBalExpDisplay', 'getRecommendedMatch','isClickBetOn'
        ]),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    emits: ['close-mobile-sidebar'],
    methods: {
        siteRulesModal() {
            this.siteRuleModal = new Modal(this.$refs.siteRulesModal);
        },
        openBonusLogs() {
            this.showBonusLogs = true;
            
        },
        closeBonusLogs() {
            setTimeout(() => {
                this.showBonusLogs = false;
            }, 400)
        },
        getRules(bonus = false) {
            if(this.siteRuleData && !bonus) {
                this.siteRuleModal.show();
                return;
            }
            if(this.siteRuleData) {
                return;
            } 
            this.$store.dispatch('setGlobalLoader', true);
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.GET_SITE_RULES, { headers }).then(response => {
                this.$store.dispatch('setGlobalLoader', false);
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let ruleResp = response.data.data;
                            this.siteRuleData = {}
                            ruleResp.forEach(item => {
                                this.siteRuleData[item.type] = item.content;
                            })
                            if(!bonus) {
                                this.siteRuleModal.show();
                            }
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        updateWalletType() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.post(apiName.UPDATE_WALLET_TYPE, {} , {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            let user = this.stateUser;
                            user.activeWallet = response.data.data.active_wallet;
                            this.$store.dispatch('setUser', user);
                            this.$emit('success-modal', 'Successfully changed');
                            setTimeout(() => {
                                this.$emit('updateAuthToken', true);
                            },2000)
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.getWalletType = !this.getWalletType;
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getLockedEarnedBonus() {
            let authToken = this.$cookies.get(AUTH_TOKEN);
			let headers = {
				'Authorization': `Bearer ${authToken}`
			}
            api.get(apiName.GET_LOCKED_EARNED_BONUS, {headers}).then(response => {
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.lockedEarnedData = response.data.data;
                        }
                    }
                }
            }).catch(error => {
                this.$store.dispatch('setGlobalLoader', false);
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        callPage(pageName) {
            this.$router.push({ name: pageName });
        },
        logoutBtnClick() {
            this.logoutServiceCall();
            //this.clearDataAndRedirect();
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
        logoutServiceCall() {
            
            this.$store.dispatch('setGlobalLoader',true)
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            localStorage.removeItem('is_offer');
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.clearDataAndRedirect();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clearDataAndRedirect() {
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
            this.$store.dispatch('resetAllState');
            this.$router.push({ name: 'Home' });
            this.$refs.close_btn.click();
        },
        betCheckChangeFunc() {
            if (this.clickBetCheck) {
                this.$refs.open_click_bet_value.click();
            }
            else {
                this.$store.dispatch('setIsClickBetOn', false);
                this.showSuccessModalFunc('One Click Bet is inactive.')
                // this.$store.dispatch('setClickBetValue', null);
            }
        },
        getClickBetSelectedValue() {
            let inputValues = this.getClickBetValue();
            if (inputValues)
                return inputValues.filter(item => item.isSelected)[0]?.value;
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? [...this.$store.getters.clickBetValue] : null;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}

</script>