const SettingModule = () => import(/* webpackChunkName: "setting-module" */ './views/Module.vue');
const Setting = () => import(/* webpackChunkName: "setting" */ './views/Setting.vue');
const TimeSetting = () => import(/* webpackChunkName: "time-setting" */ './views/TimeSetting.vue');
const ChangePassword = () => import(/* webpackChunkName: "change-password" */ './views/ChangePassword.vue');

const SettingRoutes = {
    path: '/',
    component: SettingModule,
    children: [
        {
            path: 'setting',
            name: 'setting',
            component: Setting
        },
        {
            path: 'time-setting',
            name: 'time-setting',
            component: TimeSetting
        },
        {
            path: 'change-password',
            name: 'change-password',
            component: ChangePassword
        }
    ],
}

export default SettingRoutes;