<template>
    <section class=" p-2" v-if="siteSettings && ($route.name=='Home' || $route.name=='sports' || $route.matched.some(route => route.components.default.name === 'GamesModule'))">
        <section v-if="siteSettings && ($route.name=='Home' || $route.name=='sports')" class="banner-section">
            <Carousel :wrapAround="true" :breakpoints="breakpoints" :items-to-show="1" :autoplay="2500"
                class="owl-carousel owl-theme">
                <Slide v-for="(poster,index) in sitePosters" :key="index">
                    <div class="item">
                        <div class="main-slider-img owl-item">
                            <figure><img :src="siteSettings?.aws_url + siteSettings?.storage_path.poster_path + poster?.image"
                                    :alt="poster?.image" /></figure>
                        </div>
                    </div>
                </Slide>
            </Carousel>
        </section>



        <section class="game-cat-sec" v-if="($route.name=='Home' || $route.name=='sports')">
            <ul class="cmn-ul-list">
                <li v-for="(sport, n_index) in non_custom_sports" :key="n_index">
                    <a @click="callSportPageFunc(sport)">
                        <div class="game-cat-card">
                            <img v-if="sport.id == 4" class="game-cat-icn" src="@/assets/images/cricket-bg.webp" alt="">
                            <img v-if="sport.id == 1" class="game-cat-icn" src="@/assets/images/football-bg.webp" alt="">
                            <img v-if="sport.id == 2" class="game-cat-icn" src="@/assets/images/tennis-bg.webp" alt="">
                            <div class="cat-title-bx">
                                <img v-if="sport.id == 4" src="@/assets/images/cat-cricket-icon.webp" alt="">
                                <img v-if="sport.id == 1" src="@/assets/images/cat-football-icon.webp" alt="">
                                <img v-if="sport.id == 2" src="@/assets/images/cat-tennis-icon.webp" alt="">
                                <span>{{ sport.name == 'soccer' ? translatedLangData('football' ,'Football' ):  translatedLangData(sport.slug,sport.name) }}</span>
                            </div>
                        </div>
                    </a>
                </li>
                <li v-for="(sport, n_index) in custom_sports" :key="n_index">
                    <a @click="callSportPageFunc(sport)">
                        <div class="game-cat-card">
                            <img class="game-cat-icn" src="@/assets/images/cricket-bg.webp" alt="">
                            <div class="cat-title-bx">
                                <img loading="lazy" src="@/assets/images/cat-cricket-icon.webp" alt="">
                                <span>{{ sport.name }}</span>
                            </div>
                        </div>
                    </a>
                </li>
                <li  v-if="checkEventManageforSingleType('worli-matka')">
                    <router-link :to="{ name: 'matka' }">
                        <div class="game-cat-card">
                            <img class="game-cat-icn" src="@/assets/images/matka-header-menu.webp" alt="">
                            <div class="cat-title-bx">
                                <img loading="lazy" src="@/assets/images/matka-icon.webp" alt="">
                                <span>{{translatedLangData('matka', 'Matka')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <li >
                    <router-link :to="{ name: 'cricketFight' }">
                        <div class="game-cat-card">
                            <img class="game-cat-icn" src="@/assets/images/cricket-bg.webp" alt="">
                            <div class="cat-title-bx">
                                <img loading="lazy" src="@/assets/images/cricket-bet.svg" alt="">
                                <span>{{translatedLangData('cricket-fight','Cricket Fight')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </section>
        <section class="most-games-sec" v-if="($route.name=='Home' || $route.name=='sports' || $route.matched.some(route => route.components.default.name === 'GamesModule'))">
            <ul class="cmn-ul-list">
                <li>
                    <!-- <a @click="$emit('click-event','RG-LIVELOBBY')"> -->
                    <router-link :to="{ name: 'royal-gaming' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/aurabg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/evolution-gaming-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('royal-gaming','Royal Gaming')}}</span>
                            </div>
                        </div> 
                    </router-link>
                </li>

                <li>
                    <!-- <a @click="$emit('click-event','evo_topcardlobby')"> -->
                    <router-link :to="{ name: 'evolution' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/evolution-gaming-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/evolution-gaming-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('evolution-gaming','Evolution Gaming')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{ name: 'live-casino' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/live-casinos-bg.webp" alt="">
                            <div class="m-game-cat-name"><i class="m-game-icn"><img loading="lazy" src="@/assets/images/live-casinos-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('live-casino','Live Casinos')}}</span></div>
                        </div>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{ name: 'indian-card-games' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/card-games-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/card-games-icon.webp" alt="" /></i><span>{{translatedLangData('card-games','Card Games')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{ name: 'multiplayer-games' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/multiplayer-games-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/multiplayer-games-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('multiplayer-games','Multiplayer Games')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                <!-- <li>
                    <a href="Javascript:void(0);">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/aviator-game-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/aviator-game-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('aviator','Aviator')}}</span>
                            </div>
                        </div>
                    </a>
                </li> -->
                <li v-if="checkEventManageforSingleType('sports-book')">
                    <a href="Javascript:void(0);" >
                        <div class="m-game-card" @click="gotoSportsbook()">
                            <img class="m-game-cat-bg-img" src="@/assets/images/sportsbook-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/sportsbook-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('sportsbook','Sportsbook')}}</span>
                            </div>
                        </div>
                    </a>
                </li>
                <!-- <li>
                    <router-link to="/">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/e-cricket-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/e-cricket-icon.webp"
                                        alt="" /></i><span>e-Cricket</span>
                            </div>
                        </div>
                    </router-link>
                </li> -->
                
                <li>
                    <router-link :to="{ name: 'slot-games' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/slot-games-bg.webp" alt="">
                            <div class="m-game-cat-name"><i class="m-game-icn"><img loading="lazy" src="@/assets/images/slot-games-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('slot-games','Slot Games')}}</span></div>
                        </div>
                    </router-link>
                </li>
                
                <li>
                    <router-link :to="{ name: 'fishing-games' }">
                        <div class="m-game-card">
                            <img class="m-game-cat-bg-img" src="@/assets/images/fishing-games-bg.webp" alt="">
                            <div class="m-game-cat-name">
                                <i class="m-game-icn"><img loading="lazy" src="@/assets/images/fishing-games-icon.webp"
                                        alt="" /></i><span>{{translatedLangData('fishing-games','Fishing Games')}}</span>
                            </div>
                        </div>
                    </router-link>
                </li>
                
            </ul>
        </section>
    </section>
    
</template>

<script>

import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { mapGetters } from 'vuex';

export default {
    name: "Banner",
    emits:['click-event'],
    inject:['translatedLangData'],
    data() {
        return {
            breakpoints: {
                0: {
                    itemsToShow: 1,
                    snapAlign: 'start',
                },
                480: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                668: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                }
            },
            eventManage:null
        }
    },
    created(){
		this.eventManage = this.$store?.getters?.eventManage;
    },
    computed: {
        ...mapGetters([
            'siteSettings'
        ]),
        sitePosters() {
            if (this.siteSettings && this.siteSettings.poster && this.siteSettings.poster.length) {
                return this.siteSettings.poster.filter(s => s.poster_type == 1);
            } else {
                return [];
            }
        },
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
    components: {
        Carousel,
        Slide,
        Pagination
    },
    data() {
        return {
            custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).custom : [],
            non_custom_sports: JSON.parse(localStorage.getItem('sports_List')) ? JSON.parse(localStorage.getItem('sports_List')).non_custom : [],
        }
    },
    methods: { 
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin) {
                let sportEventManage = this.eventManage?.find(item => item.type == type);
                
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;    
        },
        gotoSportsbook() {
            if(this.checkIsLogin) {
                this.$router.push({ name: 'sports-book' })
            } else {
                this.$emit('error-modal', this.translatedLangData('login-for-sportsbook','Please Login to Access Sportsbook!!'));
            }
        },
        callSportPageFunc(sport) {
            // this.$router.push({ name: 'sports', params: { type: type}})
            this.$router.push('/sports/' + sport.name + '/' + sport.id)
        },
    }
};
</script>

<style scoped>
.promotion-con {
    width: 240px;
    height: 120px;
    border-radius: 10px;
}

.promotion-con a {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.promotion-con a img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}</style>