<template>
    <div class="modal-dialog rules-modal" :class="{'d-none' : !siteRuleData}">
        <div class="modal-content">
            <div class="modal-header">
                <h5>{{ translatedLangData('rules', 'Rules') }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body rules-and-regulation" ref="modalBody" @scroll="handleScroll">
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            {{ translatedLangData('general', 'General') }}
                        </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="panel-body card-block card-body">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <span  v-html="siteRuleData?.['General']"></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div v-if="showGoToTopButton" class="back-to-top thm-but rules-go-to-top" @click="goToTop()"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "RulesModal",
        props: ['siteRuleData'],
        inject: ['translatedLangData'],
        data() {
            return {
                showGoToTopButton: false
            }
        },
        watch: {
            siteRuleData() {
                this.$nextTick(this.handleScroll);
            }
        },
        methods: {
            goToTop() {
                const modalBody = this.$refs.modalBody;
                if (modalBody) {
                    modalBody.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                }
            },
            handleScroll() {
                const modalBody = this.$refs.modalBody;
                if (modalBody.scrollTop > 200) {
                    this.showGoToTopButton = true;
                } else {
                    this.showGoToTopButton = false;
                }
            }
        }
    };
</script>
<style scoped>
    .rule-height {
        overflow-y: auto;
        height: calc(100vh - 110px);
    }
    .rules-go-to-top {
        position: fixed;
        z-index: 99;
        right: 12px;
        bottom: 63px;
    }
    .modal-body {
        position: relative;
    }
    @media screen and (max-height: 424px){
        .rules-modal{
            position:  relative !important;
        }
    }
</style>