<template>
    <div class="sidebar-details">
        <div class="menu-sec cricket-menu">
            <ul>
                <li class="details-item" v-if="checkIsLogin">
                    <router-link :to="{name:'MultiMarket'}" class="details-link" @click="$emit('close-side-nav')">
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/my-market.webp" alt="search-icon"></i>
                        <span class="menu-title">{{translatedLangData('my-market', 'My Markets')}}</span>
                    </router-link>
                </li>
                <!-- <li class="details-item"  v-if="checkIsLogin()">
                    <router-link :to="{name:'favourites'}" class="details-link" @click="$emit('close-side-nav')">
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/favourites-icon.webp" alt="search-icon"></i>
                        <span class="menu-title">Favourites</span>
                    </router-link>
                </li> -->
                <li class="details-item"  v-for="(sport,n_index) in non_custom_sports" :key="n_index">
                    <router-link :to="'/sports/' + sport.name + '/' + sport.id" class="details-link" @click="$emit('close-side-nav')">
                        <i class="sport-icn-img">
                            <img v-if="sport.id==4" src="@/assets/images/cricket-ball.png" alt="Banner-icon">
                            <img v-if="sport.id==1" src="@/assets/images/football-icon.png" alt="Banner-icon">
                            <img v-if="sport.id==2" src="@/assets/images/tennis-icon.png" alt="Banner-icon">
                        </i>
                        <span class="menu-title">{{ sport.name == 'soccer' ? translatedLangData('football' ,'Football' ):  translatedLangData(sport.slug,sport.name) }}</span>
                    </router-link>
                </li>
                <li class="details-item"  v-for="(c_sport, c_index) in custom_sports" :key="c_index">
                    <router-link :to="'/sports/' + c_sport.name + '/' + c_sport.id" class="details-link" @click="$emit('close-side-nav')">
                        <i class="sport-icn-img" >
                            <img :src="c_sport.sport_icon" alt="" v-if="c_sport?.sport_icon">
                            <img src="@/assets/images/cricket-ball.png" alt="" v-else>
                        </i>
                        <span class="menu-title">{{c_sport.name}}</span>
                    </router-link>
                </li>
                
              
                <li class="details-item">
                    <router-link  to="/indian-card-games" class="details-link" @click="$emit('close-side-nav')" >
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/card-games.webp" alt="Banner-icon"></i>
                        <span class="menu-title">{{translatedLangData('indian-card-games', 'Indian Card Games')}}</span>
                    </router-link>
                </li>
                <li class="details-item" v-if="checkEventManageforSingleType('sports-book')">
                    <router-link :to="{ name: 'sports-book' }" class="details-link" @click="$emit('close-side-nav')" >
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/sportsbook.webp" alt="Banner-icon"></i>
                        <span class="menu-title">{{translatedLangData('sportsbook', 'Sports Book')}}</span>
                    </router-link>
                </li>
                <li class="details-item">
                    <router-link  to="live-casino" class="details-link" @click="$emit('close-side-nav')" >
                        <i class="sport-icn-img"> <img loading="lazy" src="@/assets/images/live-casino.webp" alt="Banner-icon"></i>
                        <span class="menu-title">{{translatedLangData('live-casino', 'Live Casino')}}</span>
                    </router-link>
                </li>
                <li class="details-item">
                    <router-link  to="slot-games"  class="details-link" @click="$emit('close-side-nav')" >
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/slots-games.webp" alt="Banner-icon"></i>
                        <span class="menu-title">{{translatedLangData('slot-games','Slot Games')}}</span>
                    </router-link>
                </li>
                <li class="details-item">
                    <router-link  to="#language_selection_pop_up"  data-bs-toggle="modal"  class="details-link" @click="$emit('close-side-nav')" >
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/lang-icon.webp" alt="Banner-icon"></i>
                        <span class="menu-title">{{translatedLangData('language', 'Language')}}</span>
                    </router-link>
                </li>
            </ul>
        </div>

        <div class="sidebar-register-pay-oftion" v-if="siteSettings && siteSettings.business_type == 2">
            <h4>{{translatedLangData('reg-onl-play-onl', 'Get Instant ID On Whatsapp')}}</h4>
                <div class="instagram-button">
                    <a class="whatsapp-ico" v-if="siteSettings?.social_link?.whatsappno1" :href="'//wa.me/' + siteSettings.social_link?.whatsappno1" target="_blank" ></a>
                    <a class="insta-ico" v-if="siteSettings?.social_link?.instagram_link"  :href="siteSettings?.social_link?.instagram_link" target="_blank"></a>
                    <a class="telegram-ico" v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link" target="_blank" ></a>
                    <a class="fb-ico" v-if="siteSettings?.social_link?.facebook_link"  :href="siteSettings?.social_link?.facebook_link" target="_blank"></a>
                    
                </div>
        </div>

        <!--Sidebar Sub Menu Start  Add Class Category wise add class mention here all cat class : 
           cricket-menu, footbal-menu, tennis-menu, horse-menu, greyhound-menu  
         -->
        <div class="menu-sec cricket-menu sub-menu-active d-none">
            <ul>
                <li class="details-item">
                    <router-link :to="{name:'MultiMarket'}" class="details-link" v-if="checkIsLogin">
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/my-market.webp" alt="search-icon"></i>
                        <span class="menu-title">My Markets</span>
                    </router-link>
                </li>
                <li class="details-item">
                    <router-link :to="{name:'favourites'}" class="details-link" v-if="checkIsLogin">
                        <img loading="lazy" src="@/assets/images/favourites-icon.webp" alt="search-icon">
                        <span class="menu-title">Favourites</span>
                    </router-link>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/home-sports.webp" alt="Banner-icon">
                        <span class="menu-title">Sports</span>
                    </a>
                </li>
                <li class="details-item active">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/cricket-icon.webp" alt="Player-icon">
                        <span class="menu-title">Cricket</span>
                    </a>
                </li>
                <li class="details-item previous-btn">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/arrow-icon.webp" alt="Banner-icon">
                        <span class="menu-title">Previous</span>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" href="indian-premie.html">
                        <span class="menu-title">Indian Premier League</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Big Bash League SRL</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">SA T20 League SRL</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">T10 ECI Portugal</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">T20 International SRL</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Pakistan Super League SRL</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Caribbean Premier League SRL</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Dhaka Premier Division Cricket League</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">ECS Cyprus T10</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Vincy T10 Premier League</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Fairbreak Invitational, Women</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!--Sidebar Sub Menu End -->

        <!--Sidebar Mega Sub Menu Start -->
        <div class="menu-sec cricket-menu mega-sub-menu-active d-none">
            <ul>
                <li class="details-item">
                    <router-link :to="{name:'MultiMarket'}" class="details-link" v-if="checkIsLogin">
                        <i class="sport-icn-img"><img loading="lazy" src="@/assets/images/my-market.webp" alt="search-icon"></i>
                        <span class="menu-title">My Markets</span>
                    </router-link>
                </li>
                <!-- <li class="details-item">
                    <router-link :to="{name:'favourites'}" class="details-link" v-if="checkIsLogin()">
                        <img loading="lazy" src="@/assets/images/favourites-icon.webp" alt="search-icon">
                        <span class="menu-title">Favourites</span>
                    </router-link>
                </li> -->
                <li class="details-item">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/home-sports.webp" alt="Banner-icon">
                        <span class="menu-title">Sports</span>
                    </a>
                </li>
                <li class="details-item active">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/cricket-icon.webp" alt="Player-icon">
                        <span class="menu-title">Cricket</span>
                    </a>
                </li>

                <li class="details-item previous-btn">
                    <a class="details-link" >
                        <img loading="lazy" src="@/assets/images/arrow-icon.webp" alt="Banner-icon">
                        <span class="menu-title">Previous</span>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Indian Premier League</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Gujarat Titans v Kolkata Knight Riders</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Sunrisers Hyderabad v Punjab Kings</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Royal Challengers Bangalore v Lucknow Super Giants</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Delhi Capitals v Mumbai Indians</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Chennai Super Kings v Rajasthan Royals</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Punjab Kings v Gujarat Titans</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Kolkata Knight Riders v Sunrisers Hyderabad</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Lucknow Super Giants v Punjab Kings</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
                <li class="details-item">
                    <a class="details-link" >
                        <span class="menu-title">Royal Challengers Bangalore v Delhi Capitals</span>
                        <i class="fa-solid fa-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </div>
        <!--Sidebar Mega Sub Menu End -->
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import * as constants from '../../../modules/sports/utils/constants';

export default {
    name: 'SideMenu',
    emits:['close-side-nav'],
    inject:['translatedLangData'],
    computed:{
        custom_sports(){
            return this.sports_list?.custom ?? [];
        },
        non_custom_sports(){
            return this.sports_list?.non_custom ?? [];
        },
        ...mapGetters({
            siteSettings:'siteSettings',
			sports_list: 'sports_list'
		}),
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
    data(){
        return{
            eventManage:null
        }
    },
    created(){
		this.eventManage = this.$store?.getters?.eventManage;
    },
    methods: {
        
        checkEventManageforSingleType(type) {
            if(this.checkIsLogin) {
                let sportEventManage = this.eventManage?.find(item => item.type == type);
                
                if(type == 'worli-matka' && sportEventManage && sportEventManage.type_id != null ) {
                    return true
                }
                return !sportEventManage
            }
            return true;    
        },
    }
}
</script>