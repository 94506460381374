<template>
    <div class="casino-model-pop-btn">
        <div class="modal fade" id="login-casino-btn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px !important;">
                        <button ref="close_btn" @click="refreshData()" type="button" class="btn-close"
                            data-bs-dismiss="modal">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                        <div class="sign-and-login-btn">

                            <div class="register-form">
                                <div v-if="siteSettings" class="logo-image">
                                    <img :src="siteSettings?.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo">
                                </div>
                                <div class="login-step-title">{{ translatedLangData('log-in','Login Now')}}</div>
                                <div class="login-tab-header">
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item" v-if="siteSettings && siteSettings?.business_type == 2">
                                            <a class="nav-link" :class="form.type == 1 ? 'active': ''" data-bs-toggle="tab" href="#mobile-tab" @click="form.mobileNo=''; form.type = 1"><img
                                                    src="@/assets/images/otp.webp">{{ translatedLangData('mobile-number','Mobile Number')}}</a>
                                        </li>
                                        <li class="nav-item" v-if="siteSettings && siteSettings?.business_type == 2">
                                            <a class="nav-link" :class="form.type == 2 ? 'active': ''" data-bs-toggle="tab" href="#user-tab" @click="form.userId=''; form.type = 2"><img
                                                    src="@/assets/images/user-tab.webp" >{{ translatedLangData('user-id','User ID')}}</a>
                                        </li>

                                    </ul>
                                </div>
                                <!--Login Form Start -->
                                <div class="login-form">
                                    <div class="input-form-sec">
                                        <div class="mak-gin text-danger">
                                            <div class="tab-content">
                                                <div class="tab-pane" :class="form.type == 1 ? 'active': ''" id="mobile-tab" v-if="siteSettings?.business_type == 2">
                                                    <div class="row">
                                                        <div class="col-3 col-sm-3 col-md-3">
                                                            <select v-model="form.code"
                                                                class="form-select form-control codein"
                                                                aria-label="Default select example">
                                                                <option :value="allowedCountry.phonecode"
                                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                    :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div
                                                            :class="form.type == 1 ? 'col-9 col-sm-9 col-md-9' : 'col-9 col-sm-9 col-md-9'">
                                                            <div class="whatsup-sec">
                                                                <div class="input-left">
                                                                    <input @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" type="tel"
                                                                        class="form-control" id="mobile" maxlength="10"
                                                                        v-model="form.mobileNo"
                                                                        :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="tab-pane" id="user-tab" :class="form.type == 2 ? 'active': ''">
                                                    <div class="row">
                                                        <div
                                                            :class="form.type == 1 ? 'col-12 col-sm-12 col-md-12' : 'col-12 col-sm-12 col-md-12'">
                                                            <div class="whatsup-sec">
                                                                <div class="input-left">
                                                                    <input type="text" class="form-control" id="mobile" v-model="form.userId"
                                                                    @input="filterSpaces($event,'userId'),handleInputUserID"
                                                                    :placeholder="translatedLangData('user-id','User ID')+'*'" >
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="showValidationError && getId() == ''" class="thm-heading justify-content-start mt-1">
                                                <p class="wrong-color">{{ form.type == 1 ? translatedLangData('please-enter-mobile-number','Please Enter Mobile Number'): translatedLangData('please-enter-user-id','Please Enter User ID')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form-sec">
                                        <input :type="form.passwordVisible ? 'text' : 'password'" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" @input="filterSpaces($event,'password')" class="form-control"  />
                                        <div class="score-hide-show">
                                            <img loading="lazy" @click="form.passwordVisible = !form.passwordVisible"
                                                class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                v-if="form.passwordVisible" alt="img">
                                            <img loading="lazy" v-else
                                                @click="form.passwordVisible = !form.passwordVisible"
                                                class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"
                                                alt="img">
                                        </div>

                                        <div v-if="showValidationError && form.password == ''">
                                            <p class="wrong-color">{{translatedLangData('please-enter-password','Please Enter Password')}}</p>
                                        </div>
                                    </div>

                                    <!-- <Captcha :showValidationError="showValidationError" :form="form" /> -->

                                    <div class="forgot-password-link" v-if="siteSettings && siteSettings.business_type == 2">
                                        <div class="forpass">
                                            <a data-bs-toggle="modal" data-bs-target="#forget-password-btn" @click="refreshData">
                                            {{ translatedLangData('forgot-password','Forgot Password?') }}
                                            </a>
                                        </div>
                                        </div>
                                    <div class="login-and-reg-cmn-btn">
                                        <button @click="signInMethodCall()" class="login-cmn-btn" :disabled="loading" type="button">
                                            <span v-if="!loading">{{ translatedLangData('log-in','Log In') }}</span>
                                            <div v-if="loading" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="login-and-reg-cmn-btn">
                                        <button type="button" class="login-cmn-btn" @click="demoUserLogin()">
                                            <span v-if="!demoLoading">{{ translatedLangData('demo-login','Login with Demo ID')}}</span>
                                            <div v-if="demoLoading" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div class="registerWith-content" v-if="siteSettings?.business_type == 2">
                                        <div class="register-chose">{{ translatedLangData('or-login-with','or Login with') }}</div>                                        
                                        <div class="login-modal-social-link" v-if="siteSettings?.social_link">
                                            <a v-if="siteSettings?.social_link?.facebook_link" :href="siteSettings?.social_link?.facebook_link" target="_blank"><img src="@/assets/images/facebook-icon.webp"
                                                    alt=""></a>
                                            <a v-if="siteSettings?.social_link?.google_link" :href="siteSettings?.social_link?.google_link" target="_blank"><img src="@/assets/images/google-icon.webp"
                                                    alt=""></a>
                                            <a v-if="siteSettings?.social_link?.instagram_link"  :href="siteSettings?.social_link?.instagram_link" target="_blank"><img src="@/assets/images/instagram.webp" alt=""></a>
                                            <a v-if="siteSettings?.social_link?.telegram_link" :href="siteSettings?.social_link?.telegram_link" target="_blank"><img src="@/assets/images/telegram.webp" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="registerWith-content" v-if="siteSettings?.social_link?.whatsappno1 && siteSettings?.business_type == 2">
                                        <div class="register-chose">{{ translatedLangData('or-continue-with-whatsapp','Or Continue With Whatsapp') }}</div>
                                        <div class="login-modal-social-link">
                                            <a v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link?.whatsappno1"
                                                :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"
                                                class="btn-whatsapp"><img loading="lazy" src="@/assets/images/whatapp-icon.webp" alt="">
                                                {{ translatedLangData('whatsapp-now','Whatsapp Now') }}</a>
                                        </div>
                                    </div>
                                    <template  v-if="siteSettings && siteSettings?.business_type == 2">
                                        <div class="acc-and-join-sec">
                                            <span>{{ translatedLangData('dont-have-an-account',`Don't have an account `) }}?</span>
                                            <span @click="refreshData"><a href="#sign-up-btn" data-bs-toggle="modal">{{ translatedLangData('register','Register') }}</a></span>
                                        </div>
                                        <div class="web-site-h">
                                            <a :href="'mailto:'+siteSettings?.social_link?.email">{{siteSettings?.social_link?.email}}</a>
                                        </div>
                                    </template>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Login Form End-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import { Modal } from "bootstrap"


export default {
    name: 'Login',
    inject: ['translatedLangData'],
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        domainName() {
            return this.$store.getters.domainName;
        }
    },
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                passwordVisible: false,
            },
            inputvalue:[
                    {
                        value: 1000,
                        isSelected: true,
                    },
                    {
                        value: 2000,
                        isSelected: false,
                    },
                    {
                        value: 3000,
                        isSelected: false,
                    },
                    {
                        value: 4000,
                        isSelected: false,
                    }
                ],
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
            loggedUserCheck:null
        };
    },
    mounted() {
        this.loggedUserCheck=localStorage.getItem('LoggedUser')
        this.form.type = this.siteSettings?.business_type == 1 ? 2 : 1;
        if (this.$route.name == 'login' && !this.checkIsLogin()) {
            setTimeout(()=> {
                let loginModal = new Modal('#login-casino-btn');
                loginModal.show();
            }, 500)
            this.$router.push({name: 'Home'})            
        }
        else if (this.$route.name == 'login' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    methods: {
        handleInputUserID(event) {
            // this.form.userId = event.target.value.replace(/[^\w@#$%^&*()+\-*/]/g, ''); // Allow alphanumeric characters, underscore, and special characters like @#$%^&*()+-*/.
            const containsSpaces = /\s/.test(this.form.userId);
            if (containsSpaces) {
                this.form.userId = this.form.userId.replace(/\s/g, '');
            }
            event.target.value = this.form.userId;
        },
        filterSpaces(event, prop) {            
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },
        numberFormat(prop) {
            // Convert the input to a string
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            // Prevent input of the letter 'e'
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: this.siteSettings?.business_type == 1 ? 2 : 1,
            };
                this.showValidationError = false
        },
        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo')
        },

        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        async loginServiceCall(url, type) {
            let id = this.getId();
            type == 'demo' ? this.demoLoading = true : this.loading = true;
            let data = {
                "userid": id.toString(),
                "type": this.form.type,
                "password": this.form.password,
                "cncode": this.form.code
            };
            api.post(url, data).then(response => {
                type == 'demo' ? this.demoLoading = false : this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {

                            this.$emit('success-modal', response.data.message);
                            let userData = response.data.data;
                            if(this.loggedUserCheck!=userData.userid){
                               this.$store.dispatch('setClickBetValue',this.inputvalue);
                               this.$store.dispatch('setIsClickBetOn',false);
                            }
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            this.$store.dispatch('setSiteVersion', null);
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            localStorage.setItem("LoggedUser",userData.userid)
                            setHeaders();
                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.closeModal();
                            location.reload();
                        }
                    }
                }
            }).catch(error => {
                type == 'demo' ? this.demoLoading = false : this.loading = false;
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clickOnType(type) {
            this.form.type = type;
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            if (this.siteSettings?.business_type == 2) {
                return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
            } else {
                this.form.type = 2;
                return this.form.userId;
            }
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let asciiCodes = null;
            for (let i = 0; i < userData.userid.length; i++) {
                asciiCodes += userData.userid.charCodeAt(i)
            }
            let user =
            {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.expl,
                available_credits: userData.available_credits,
                is_fresh: userData.is_fresh,
                id: userData.id,
                exp: userData.exp,
                activeWallet: userData.actw,
                cpi: userData.cpi,
                notificationSubscribed:null,
                notificationUnsubscribed:null,
                asciiCodes: asciiCodes,
            }
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
            this.$store.dispatch('setEventManage', userData.lock_settings);
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
}
</script>
