<template>	
	<div class="exposure">
		<div class="modal fade mymarket-modal-box setbutton-value-wrapper" id="buttonvalue" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">
						{{translatedLangData('edit-stake', 'Edit Stakes')}}
					</h5>
					<button type="button" class="btn-close my-market-close-btn" data-bs-dismiss="modal" aria-label="Close"
						ref="closeMyMarket" @click="resetChips">
					</button>
				</div>
					<div class="modal-body">
						<div class="change-btn-value">
							<form action="">
								<div class="stakebtn-row">
									<ul v-if="chips" class="change-value-btn-lft">
										<li  v-for="(chipKey, index) in chipKeys" :key="index">
											<div class="stack-page-items">
												<input
													:class="lessThan100Set?.has(parseInt(chips[chipKey])) || duplicateSet?.has(parseInt(chips[chipKey])) || chips[chipKey] == '' ? 'border border-danger' : ''"
													type="tel" @input="handleInput(chipKey)" @keypress="checkForNonNumericals($event)"
													v-model="chips[chipKey]" maxlength="8" class="form-control" :placeholder="translatedLangData('button-value','Button Value')"/>
											</div>                                    
										</li>
									</ul>
								</div>
								<div class="stake-edit-btn">
									<button class="btn btn-primary" @click.prevent="callChangeStakeValue()">{{translatedLangData('save', 'Save')}}</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
      	</div>
	</div>
</template>

<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { kFormatter } from '@/shared/utils/formatter';
//import SideMenu from '@/shared/components/header/SideMenu.vue';

export default {
	name: 'SetValue',
	// components: { SideMenu },
    inject: ['translatedLangData'],
	data() {
		return {
			chips: null,
            chipsDummy: null,
            userData: null,
            duplicateSet: new Set(),
            lessThan100Set: new Set()
		}
	},
	computed: {
        chipKeys() {
            return Object.keys(this.chips).filter(key => key.startsWith('chip_val'));
        }
    },
	mounted() {
		this.chips = { ...this.$store?.getters?.chips };
		this.chipsDummy = { ...this.$store?.getters?.chips };
		this.userData = this.$store?.getters?.stateUser;
	},	
	methods: {
		hasDuplicates() {
            // const chipValKeys = Object.keys(this.chips).filter(key => key.startsWith('chip_val'));
            const chipValValues = this.chipKeys.map(key => parseInt(this.chips[key]));
            const valueSet = new Set();
            this.duplicateSet.clear();
            this.lessThan100Set.clear();
            for (const value of chipValValues) {
                if (value < 100) {
                    this.lessThan100Set.add(value);
                }
                if (valueSet.has(value)) {
                    this.duplicateSet.add(value);
                } else {
                    valueSet.add(value);
                }
            }
            return this.lessThan100Set.size > 0 || this.duplicateSet.size > 0;
        },
		numberFormat(prop) {
            // Convert the input to a string
            if (this.chips[prop] != '') {
                let stringValue=''
                if(typeof this.chips[prop]!='string'){
                    stringValue = this.chips[prop].toString();
                }
                else{
                    stringValue = this.chips[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                // Convert the sanitized string back to a number
                if(typeof this.chips[prop]!='string'){
                    this.chips[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.chips[prop] = stringValue
                }                
            }
        },
        handleInput(key) {
            this.numberFormat(key);
            this.checkLimit(key);
            this.hasDuplicates();
        },
        numberFormat(key) {
            if (this.chips[key] != '') {
                let stringValue = typeof this.chips[key] !== 'string' ? this.chips[key].toString() : this.chips[key];
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, '');
                this.chips[key] = stringValue === '' ? '' : parseInt(stringValue);
            }
        },
        checkForNonNumericals(event) {
            if (['e', '-', '+', '.'].includes(event.key.toLowerCase())) {
                event.preventDefault();
            }
        },
        checkLimit(index) {
            if(this.chips[index]!=''){
                this.chips[index] = Math.max(0, Math.min(10000000, this.chips[index]));
            }
        },
        callChangeStakeValue() {
            if (!this.hasDuplicates()) {
                let chipsData = {
                    chips: {
                        ...this.chips
                    }
                };
                let headers = {
                    Authorization: `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                };

                this.loading = true;
                this.$store.dispatch('setGlobalLoader',true)
                api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                    this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                    if (response && response.status == 200) {
                        this.$store.dispatch('setChips', chipsData.chips);
                        this.showSuccessModalFunc(this.translatedLangData('saved-successfully','Saved Succesfully'));
                        this.$emit('save-btn');
                        this.$refs.closeMyMarket.click();
                    } else {
                    }
                }).catch(error => {
                    this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                    if (error) {
                        this.showErrorModalFunc(error[0]);
                    }
                });
            }
            else {
                if (this.lessThan100Set) {
                    this.showErrorModalFunc(this.translatedLangData('less-100-not', 'Values Less Than 100 Are Not Allowed'));
                }

                if (this.duplicateSet.size > 0) {
                    this.showErrorModalFunc(this.translatedLangData('dup-val-not', 'Duplicate Price Values Are Not Allowed'));
                }
            }
        },
		getKFormatter(num, name) {
			let minStake = 100;
			let maxStake = 10000000;

			if (num < minStake || isNaN(num)) {
				this.showErrorModalFunc(this.translatedLangData('stake-100-1cr', 'Stake must be between 100 and 1Cr.'));
				this.chips[name] = minStake;
			} else if (num > maxStake) {
				this.showErrorModalFunc(this.translatedLangData('stake-100-1cr', 'Stake must be between 100 and 1Cr.'));
				this.chips[name] = maxStake;
			} else {
				this.chips[name] = num;
			}
			return kFormatter(this.chips[name]);
		},
		resetChips() {
            this.duplicateSet.clear();
            this.lessThan100Set.clear();
			this.chips = { ...this.$store?.getters?.chips };
		},
		showErrorModalFunc(message) {
			this.$emit('error-modal', message);
		},
		showSuccessModalFunc(message) {
			this.$emit('success-modal', message);
		},
	},
	emits: ['close-stake-modal', 'success-modal', 'error-modal', 'save-btn']

}
</script>

<style></style>