<template>
    <div class="bonus-wallet-modal-sec">
        <div class="bonus-wallet-modal modal fade" id="bonus-wallet-modal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><img loading="lazy" src="@/assets/images/bonus-info-icon.webp"
                                alt="" /><span>Bonus Information</span></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="bonus-info-content">
                            <div class="right-side-menu-account-info">
                                <div class="ac-info-item">
                                    <span class="ac-info-label">Balance:</span>
                                    <span class="ac-info-value balance-bi">243.00</span>
                                </div>
                                <div class="ac-info-item">
                                    <span class="ac-info-label">Net Exposure:</span>
                                    <span class="ac-info-value net-exposure-bi">-6.00</span>
                                </div>
                                <div class="ac-info-item">
                                    <span class="ac-info-label">Use Bonus</span>
                                    <span class="ac-info-value">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" role="switch" id="use-bonus">
                                            <label class="form-check-label" for="use-bonus"></label>
                                        </div>
                                    </span>
                                </div>
                                <div class="ac-info-item bonus-wallet-content">
                                    <div class="accordion" id="accordionExample">
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                                    data-bs-target="#collapseOne" aria-expanded="true"
                                                    aria-controls="collapseOne">
                                                    Bonus Wallet
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show"
                                                aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    <div class="bonus-wallet-accordion">
                                                        <div class="ac-info-item">
                                                            <span class="ac-info-label">Wagering Remaining</span>
                                                            <span class="ac-info-value">0.00</span>
                                                        </div>
                                                        <div class="ac-info-item">
                                                            <span class="ac-info-label">Bonus Amount</span>
                                                            <span class="ac-info-value">NaN</span>
                                                        </div>
                                                        <div class="ac-info-item">
                                                            <span class="ac-info-label">Bonus Issued</span>
                                                            <span class="ac-info-value">14-Apr-23</span>
                                                        </div>
                                                        <div class="ac-info-item">
                                                            <span class="ac-info-label">Bonus Expiry</span>
                                                            <span class="ac-info-value">20-Apr-23</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="bonus-wallet-tnc">
                                    <button type="button" class="btn link-btn" data-bs-toggle="modal"
                                        data-bs-target="#bonus-wallet-tnc-modal">
                                        Terms and Conditions
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <BonusWalletTnc />
    
</template>
<script>

import BonusWalletTnc from './BonusWalletTnc.vue';

export default {
    name:'BonusWallet',
    components:{
        BonusWalletTnc
    }
}


</script>