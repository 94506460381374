<template>
    <div class="casino-model-pop-btn">
        <div class="modal fade" id="sign-up-btn" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px !important;">
                        <button type="button" @click="refreshData" class="btn-close" data-bs-dismiss="modal">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                        <div class="sign-and-login-btn">
                            <div class="register-form">
                                <div v-if="siteSettings" class="logo-image">
                                    <img :src="siteSettings?.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo">
                                </div>
                                <div class="login-step-title">{{ translatedLangData('register','Register')}}</div>                                
                                <div class="number-var mak-gin">
                                    <div class="row">
                                        <div class="col-3 col-sm-3 col-md-3">
                                            <select v-model="form.code" class="form-select form-control codein"
                                                id="autoSizingSelect" :disabled="isOtpApiCalled">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex" :disabled="allowedCountry.phonecode!='91'">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                        <div class="col-9 col-sm-9 col-md-9">
                                            <div class="whatsup-sec">
                                                <div class="input-form-sec input-left">
                                                    <input type="tel" class="form-control" :class="isMobileNoValid ? '': 'border border-2 border-danger'" @input="numberFormat('mobileNo')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('MobileNo'),showWarningsAbove()" @paste="showWarningInFields('MobileNo'),showWarningsAbove()"
                                                        id="mobile" name="mobile" :placeholder="translatedLangData('mobile-number','Mobile Number')+'*'" v-model="form.mobileNo" :disabled="isOtpApiCalled"
                                                        maxlength="10">
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div v-if="showValidationError && form.mobileNo == ''" class="">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('please-enter-mobile-number','Please Enter Mobile Number.') }}</p>
                                    </div>
                                    <div v-if="showValidationError && form.mobileNo?.length < 10 && form.mobileNo !=''" class="">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('mobile-number-should-be-of-10-digits','Mobile Number Should Be Of 10 Digits.') }}</p>
                                    </div>
                                    <div class="header-but justify-content-end mt-3">
                                        <span v-if="timerRunning" style="margin-right: 10px;color:white">{{
                                    formatTime(minutes) + ':' +
                                    formatTime(seconds) }}</span>
                                        <button @click.prevent="sendOtpCall()" type="button"
                                            :disabled="loadingOtp || timerRunning || !isMobileNoValid || this.form.mobileNo==''"
                                            class="btn cmn-bdr-btn otp-btn">
                                            <span v-if="!loadingOtp">{{ resendOtp ? translatedLangData('resend-otp','Resend OTP') : translatedLangData('get-otp',"Get OTP")}}</span>
                                            <div v-if="loadingOtp" class="text-center" id="Otp_loader">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">{{translatedLangData('loading','Loading')}}...</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>

                                    <div class="input-form-sec" v-if="isOtpApiCalled">
                                        <input v-model="form.otp" type="tel" maxlength="6" class="form-control input-boxes" :class="this.isOtpApiCalled && this.form.otp=='' ? 'border border-2 border-danger': ''" :placeholder="translatedLangData('enter-otp','Enter OTP')+'*'"
                                            name="otp" @input="numberFormat('otp')" @keypress="checkForNonNumericals($event)" @keyup="showWarningInFields('Otp'),showWarningsAbove()" id="otp-input" required="">
                                        <div v-if="showValidationError && form.otp==''"
                                            class="thm-heading justify-content-start mt-1 ">
                                            <p class="wrong-color">&#8226; {{ translatedLangData('please-enter-otp','Please Enter OTP') }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-user"></i>
                                    <input type="text" class="form-control" maxlength="25" id="inputPassword2" :disabled="isDisabled" @input="filterSpaces($event,'userName'),handleInputUserID($event,'userName')"
                                        :placeholder="translatedLangData('user-id','User ID')+'*'" v-model="form.userName" :class="(this.form.otp!='' && this.form.userName=='') || (!this.isUserNameValid && this.form.userName!='')  ? 'border border-2 border-danger': ''" @keyup="showWarningInFields('UserName'),showWarningsAbove()" @paste="showWarningInFields('UserName'),showWarningsAbove()">
                                        <div v-if="showValidationError && form.otp!='' && form.userName == ''" class="thm-heading justify-content-start mt-1">
                                            <p class="wrong-color">&#8226; {{ translatedLangData('please-enter-user-id','Please Enter User ID') }}</p>
                                        </div>
                                        <div v-if="showValidationError && !isUserNameValid && form.userName!=''" class="thm-heading justify-content-start mt-1">
                                            <p class="wrong-color">&#8226; {{ translatedLangData('user-id-alphabets-numbers-and-between-6-to-25-characters','User ID Must Contain Alphabets And Numbers And Between 6 To 25 Characters') }}</p>
                                        </div>                                        
                                </div>

                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-lock"></i>
                                    <input :type="form.passwordVisible?'text':'password'" class="form-control toggle-password" name="password" maxlength="20"
                                        id="password" :placeholder="translatedLangData('password','Password')+'*'" v-model="form.password" :disabled="isDisabled" @input="filterSpaces($event,'password'),handleInputUserID($event,'password')" :class="isPasswordFieldValid ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('PasswordField'),showWarningsAbove()" @paste="showWarningInFields('PasswordField'),showWarningsAbove()"
                                        aria-describedby="password">

                                        <div class="score-hide-show">
                                            <img loading="lazy" @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                                v-if="form.passwordVisible" alt="img">
                                            <img loading="lazy" v-else @click="form.passwordVisible=!form.passwordVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                        </div>

                                    <div v-if="showValidationError && isOtpApiCalled && form.otp && form.password == ''" class="thm-heading justify-content-start mt-1">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('please-enter-password','Please Enter Password') }}</p>
                                    </div>
                                    <div v-if="showValidationError && form.password.length<8 && form.password!=''" class="thm-heading justify-content-start mt-1">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('pass-min-8-max-20','Password Must Be Of Minimum 8 Characters And Maximum 20 Characters.') }}</p>
                                    </div>
                                    <div v-if="showValidationError && !/^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/.test(form.password) && form.password!=''" class="thm-heading justify-content-start mt-1">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('pass-alpha-num-1-caps','Password Must Contains Alphabets, Numbers And Atleast 1 In Capital Case.') }}</p>
                                    </div>
                                </div>
                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-lock"></i>
                                    <input :type="form.confirmPassVisible?'text':'password'" class="form-control toggle-password" maxlength="20"
                                        id="confirm_password" v-model="form.confirmPassword" :disabled="isDisabled" @input="filterSpaces($event,'confirmPassword'),handleInputUserID($event,'confirmPassword')" :class="isConfirmPasswordFieldValid || this.form.password === this.form.confirmPassword ? '': 'border border-2 border-danger'" @keyup="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()" @paste="showWarningInFields('ConfirmPasswordField'),showWarningsAbove()"
                                        name="confirm_password" :placeholder="translatedLangData('confirm-password','Confirm Password')+'*'"
                                        aria-describedby="password">
                                        <div class="score-hide-show">
                                            <img loading="lazy" @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-visible.webp"
                                            v-if="form.confirmPassVisible" alt="img">
                                            <img loading="lazy" v-else @click="form.confirmPassVisible=!form.confirmPassVisible" class="score-hide-icon" src="@/assets/images/score-hide-icon.webp"  alt="img">
                                        </div>

                                    <div v-if="showValidationError && form.password && form.confirmPassword==''"
                                        class="mt-2">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('please-enter-confirm-password','Please Enter Confirm Password') }}</p>
                                    </div>
                                    <div v-if="showValidationError && form.password !== form.confirmPassword && form.confirmPassword!=''"
                                        class="mt-2">
                                        <p class="wrong-color">&#8226; {{ translatedLangData('password-conf-pas-not-matched','Password And Confirm Password Not Matched.') }}</p>
                                    </div>                                    
                                </div>
                                <div class="input-form-sec icon-input-bx">
                                    <div class="call-icon"><img src="@/assets/images/signup-refer.webp"></div> 
                                    <input type="tel" class="form-control " id="refer_code" @input="referCodeValidation"
                                            :placeholder="translatedLangData('refer-code','Refer code')" v-model="form.referCode" :disabled="checkReferUrl">                                                                                 
                                </div>
                                <input type="hidden" id="isDownLine" value="">
                                <div class="login-and-reg-cmn-btn">
                                    <button :disabled="loadingSignUp || !isFormValid" @click="signUpCall()" :class="!isFormValid ? 'disabled':''" type="submit"
                                        class="login-cmn-btn" id="submitBtn">
                                        <span v-if="!loadingSignUp">{{ translatedLangData('register','Register')}}</span>
                                        <div v-if="loadingSignUp" class="text-center" id="Otp_loader">
                                            <div class="spinner-border" role="status">
                                                <span class="visually-hidden">{{ translatedLangData('loading','Loading') }}...</span>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div class="signUpNew-separator">
                                        <div class="signUpNew-separator-rightLine"></div>
                                        <div class="signUpNew-separator-or">{{translatedLangData('or','OR')}}</div>
                                        <div class="signUpNew-separator-leftLine"></div>
                                    </div>
                                <div class="whatsapp-btn">
                                    <div class="whattsup-title">{{ translatedLangData('get-your-ready-made-id-from-whatsapp','Get Your Ready-Made ID From Whatsapp')}}</div>
                                    <div class="signUpNew_icons"><img loading="lazy" src="@/assets/images/diamond-upgrade-icn.webp"
                                            alt=""><span>Diamond
                                            upgrade</span><img loading="lazy" src="@/assets/images/customer-support-icn.webp"
                                            alt=""><span>{{translatedLangData('1-1-customer-support','1 to 1 customer support')}}</span><img
                                            src="@/assets/images/instant-withdrawal-icn.webp" alt=""><span>{{ translatedLangData('247-instant-withdrawal','24/7 instant withdrawal')}}</span>
                                    </div>
                                    <div v-if="siteSettings" class="whatsapp-btn">
                                        <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"
                                            v-if="siteSettings?.social_link && siteSettings?.social_link?.whatsappno1"
                                            class="btn thm-but"><img style="position: absolute;"
                                                src="@/assets/images/whatapp-icon.webp" alt="">
                                            <span class="">{{translatedLangData('whatsapp-now','Whatsapp Now')}}</span>
                                        </a>
                                    </div>
                                    
                                </div>
                                <div class="signUpNew_info">
                                    <span class="signUpNew_info-text">{{ translatedLangData('already-have-an-account','Already have an account?')}}</span>&nbsp;
                                    <a class="signUpNew_info-link" data-bs-toggle="modal" @click="refreshData"
                                        data-bs-target="#login-casino-btn" ref="openLoginModal">{{translatedLangData('log-in','Log In')}}</a>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import api from '../services/api';
import * as apiName from '../services/urls';
import { Modal } from "bootstrap"


export default {
    name: 'SignUp',
    inject:['translatedLangData'],
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings?.country_code ? this.siteSettings?.country_code : "91",
                mobileNo: "",
                otp: "",
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                passwordVisible:false,
                confirmPassVisible:false,
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0,
            untouchedMobileNo: true,
            untouchedOtp: true,
            untouchedUserName: true,
            untouchedPasswordField: true,
            untouchedConfirmPasswordField: true,
            isDisabled:true,
            hasUrlReferer: false
        };
    },
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings'
        }),
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        isFormValid() {
            return this.getOtp() && this.form.otp!=='' &&this.form.otp?.length==6 && this.isUserNameValid && this.isPasswordFieldValid && this.form.confirmPassword == this.form.password && this.form.userName;
        },
        isMobileNoValid() {
            return this.untouchedMobileNo || this.form.mobileNo.length === 10;
        },
        isUserNameValid() {
            return this.untouchedUserName || (
                this.form.userName.length >= 6 &&
                this.form.userName.length <= 25 &&
                /^(?=.*?[A-Za-z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{6,25}$/.test(this.form.userName)
            );
        },
        isPasswordFieldValid() {
            return this.untouchedPasswordField || (
                this.form.password.length >= 8 &&
                this.form.password.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.password)
            );
        },
        isConfirmPasswordFieldValid() {
            return this.untouchedConfirmPasswordField || (
                this.form.confirmPassword.length >= 8 &&
                this.form.confirmPassword.length <= 20 &&
                /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]{8,20}$/.test(this.form.confirmPassword)
            );
        },
        checkReferUrl() {
            return this.hasUrlReferer || this.isDisabled;
        }
    },
    mounted() {
        if (this.$route.name == 'sign-up' && !this.checkIsLogin()) {
            let loginModal = new Modal('#sign-up-btn');
            loginModal.show();
        }
        else if (this.$route.name == 'sign-up' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    
    created() {
        if(this.$route.name == 'sign-up' && this.siteSettings && this.siteSettings?.business_type == 1){
            this.$router.push({ name: 'login' })
        }
        if((window.location.href).includes('refer_code')) {
            this.hasUrlReferer = true;
        }
    },
    methods: {
        referCodeValidation(event) {
            this.form.referCode = event.target.value.replace(/\D/g, '');
        },
        handleInputUserID(event,prop) {
            this.form[prop] = event.target.value.replace(/[^\w]/g, '').replace(/_/g, '').slice(0, 25);
            const containsSpaces = /\s/.test(this.form[prop]);
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
            event.target.value = this.form[prop].slice(0, 25);
        },
        filterSpaces(event,prop) {
            const sanitized = event.target.value.replace(/[^\w !@#$%^&*()+\-*/]/g, '');
            this.form[prop] = sanitized;
            const containsSpaces = /\s/.test(this.form[prop]);            
            if (containsSpaces) {
                this.form[prop] = this.form[prop].replace(/\s/g, '');
            }
        },  
        showWarningsAbove(){
            var regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/;
            this.showValidationError = false;
            if (!this.isMobileNoValid) {
                this.showValidationError = true;
            }
            if (this.isOtpApiCalled && this.form.otp=='') {
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.userName=='') {
                this.showValidationError = true;            
            }
            if(!this.isUserNameValid && this.form.userName!=''){
                this.showValidationError = true;
            }
            if (this.form.otp!='' && this.form.password=='') {
                this.showValidationError = true;            
            } 
            if(this.form.password.length<8 && this.form.password!=''){
                this.showValidationError = true;
            }
            if(!regex.test(this.form.password) && this.form.password!=''){
                this.showValidationError = true;
            }
            if (this.form.password && this.form.confirmPassword=='') {
                this.showValidationError = true;            
            }            
            if(this.form.password !== this.form.confirmPassword && this.form.confirmPassword!=''){
                this.showValidationError = true;
            }
        },       
        showWarningInFields(prop) {
            this[`untouched${prop}`] = false
        },
        numberFormat(prop) {
            if (this.form[prop] != '') {
                let stringValue=''
                if(typeof this.form[prop]!='string'){
                    stringValue = this.form[prop].toString();
                }
                else{
                    stringValue = this.form[prop]
                }
                stringValue = stringValue.replace(/[^0-9]/g, '');
                stringValue = stringValue.replace(/[+-]/g, ''); // Replace '+' and '-' characters with an empty string
                if(typeof this.form[prop]!='string'){
                    this.form[prop] = stringValue === '' ? '' : parseInt(stringValue);
                }
                else{
                    this.form[prop] = stringValue
                }                
            }
        },
        checkForNonNumericals(event) {
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
            if (event.key === '-' || event.key === '+' || event.key === '.') {
                event.preventDefault();
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: "",
                userName: "",
                password: "",
                confirmPassword: "",
                passwordVisible:false,
                confirmPassVisible:false,
                referCode: ''
            };
                this.showValidationError = false;
                this.showErrorMobile = false;
                this.passwordValidation = false;
                this.isOtpApiCalled = false;
                this.resendOtp = false;
                this.resendOtpTimer = 0;
                this.timerRunning = false;
                this.minutes = 0;
                this.seconds = 0;
                this.untouchedMobileNo= true;
            this.untouchedOtp= true;
            this.untouchedUserName= true;
            this.untouchedPasswordField= true;
            this.untouchedConfirmPasswordField= true;
            this.isDisabled=true;
        },
        sendOtpCall() {
            console.log("Loadin otp call...")
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
        async sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code.toString(),
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.showErrorMobile = false;
                        this.isDisabled = false;
                        this.$emit('success-modal', response.data.message);
                        window.scrollTo(0, 0);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                    else if (response.status == 0) {
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                if (error)
                    this.showErrorModalFunc(error[0])
            });
        },


        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },



        signUpCall() {
            if (!this.loadingSignUp) {
                var regex = /^(?=.*?[A-Z])(?=.*?[0-9])(?!.*\s)[A-Za-z0-9]*$/;
                let otp = this.getOtp();
                this.showValidationError = false;
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else if (otp.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '') {
                    this.showValidationError = true;
                }
                else if (this.form.password != this.form.confirmPassword) {
                    this.showValidationError = true;
                }
                else if (!regex.test(this.form.password)) {
                    this.showValidationError = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        async signUpServiceCall() {
            let isDownLine = this.getQueryParam("downline", window.location.href);
            this.loadingSignUp = true;
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "cncode": this.form.code,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
            };
            api.post(apiName.REGISTER, data).then(response => {
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        if (response.data.status == 0) {
                            this.showErrorModalFunc(response.data.debug[0]);
                        }
                        else {
                            this.showSuccessModalFunc(response.data.message);
                            this.refreshData();
                            this.$refs.openLoginModal.click();
                            
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.showErrorModalFunc(error[0]);
            });
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }

    },
}

</script>