

import * as apiName from '../services/urls';
import moment from 'moment';
import api from '../services/api'

class CaptchaService {
    timeoutId = null
    constructor() {
        this.captchaData = null;
    }

    getCaptchaDataServiceCall() {
        window.store.dispatch('setCaptchaData', null);
        api.get(apiName.CAPTCHA_API).then(response => {
            //this.loading = false;
            if (response && response.status == 200) {
                if (response?.data?.status === 0) {
                    //this.showErrorModalFunc(response.data.debug[0]);
                } else {
                    try {
                        let resData = response?.data?.data
                        this.captchaData = resData;
                        window.store.dispatch('setCaptchaData', this.captchaData);
                        //this.$emit('captcha-data', this.captchaData);
                        this.setTimeoutFunc();
                    }catch(error){
                        this.setTimeoutFunc();
                        console.log("Error setCaptchaData : ",error);
                    }
                    
                }
            }
        }).catch(error => {
            // this.loading = false;
            this.setTimeoutFunc();
            if (error) {
                // this.showErrorModalFunc(error[0]);
            }
        });
    }
    setTimeoutFunc() {
       
        if (this.timeoutId) {
            // Later, if you want to clear the timeout before it executes
            clearTimeout(this.timeoutId);
        }
        this.timeoutId = setTimeout(function () {
            this.getCaptchaDataServiceCall();
        }.bind(this), (40 * 1000));
    }

}

export default new CaptchaService();