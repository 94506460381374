<template>
    <div v-if="gameItem" class="modal-content">
        <div class="modal-header">
            <div class="game-header-content">
                <div class="game-bak-close-block">
                    <a ref="close_button" @click="$emit('close-modal')" data-bs-dismiss="modal"><img
                            src="@/assets/images/back-arrow.webp" alt=""></a>
                    <strong>{{gameItem?.game_name}}</strong>
                </div>
                <!-- <div class="close-game-btn">
                    <a data-bs-dismiss="modal"><img loading="lazy" src="@/assets/images/red-close-btn.webp" alt=""></a>
                </div> -->
            </div>
        </div>
        <div class="modal-body">
            <div class="game-iframe-sec">
                <div v-if="this.iFrameUrl" class="iframe-content">
                    <iframe :src="this.iFrameUrl" frameborder="0"
                        style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:100%;width:100%;position:absolute;top:0px;left:0px;right:0px;bottom:0px"
                        height="100%" width="100%" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        title="Tr&amp;eacute;ning TEXT"></iframe>
                </div>
            </div>
        </div>
    </div>

    <!-- Game Play Modal End -->
</template>
<script>
import { getGameItem } from '@/shared/constants/shared-data';
import api from '../services/api';
import * as apiName from '../services/urls';
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';

export default {
    name: 'IFrameModal',
    emits: ['error-modal', 'success-modal', 'close-modal'],
    data() {
        return {
            loading: false,
            iFrameUrl: null,
            gameItem: null
        }
    },
    mounted() {
        console.log("Game Item : ", this.getGameItemHere())
        this.gameItem = this.getGameItemHere();

        if (this.gameItem.provider == 'poker') {
            this.getLivePockerUrl()
        }
        else {
            this.getDreamCasinoLoadGames()
        }
    },
    methods: {
        getLivePockerUrl() {
            this.loading = true;
                this.$store.dispatch('setGlobalLoader',true)
            
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.GET_LIVE_POCKER_URL + this.gameItem.game_code + '?dtype=m', {}, { headers }).then(response => {
                console.log("Result : ", response);
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        this.showSuccessModalFunc(response.data.message)
                        this.closeModal();
                    }

                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error[0]);
                    this.closeButton();
                }
            });
        },

        getDreamCasinoLoadGames() {
            this.loading = true;
                this.$store.dispatch('setGlobalLoader',true)
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.post(apiName.DREAM_CASINO + this.gameItem.game_code + '/' + this.gameItem.sm_id, {}, { headers }).then(response => {
                console.log("Result : ", response);
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                if (response) {
                    if (response.status == 200) {
                        this.iFrameUrl = response?.data?.data?.url;
                        //this.showSuccessModalFunc(response.data.message)
                        if (this.iFrameUrl == null || this.iFrameUrl == undefined || this.iFrameUrl == '') {
                            this.showErrorModalFunc("URL not found.")
                            this.closeButton();
                        }
                    }

                }
            }).catch(error => {
                this.loading = false;
                this.$store.dispatch('setGlobalLoader',false)
                if (error) {
                    this.showErrorModalFunc(error[0]);
                    this.closeButton();
                }
            });
        },
        closeButton() {
            setTimeout(() => {
                this.$refs.close_button.click();
            }, 400);
        },
        getGameItemHere() {
            return getGameItem();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },
    }
}
</script>