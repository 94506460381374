const GamesModule = () => import(/* webpackChunkName: "games-module" */ './views/Module.vue');
const LiveCasino = () => import(/* webpackChunkName: "live-casino" */ './views/LiveCasino.vue');
const SlotGames = () => import(/* webpackChunkName: "live-casino" */ './views/SlotGames.vue');
const IndianCardGames = () =>  import(/* webpackChunkName: "indian-card-games" */ './views/IndianCardGames.vue');

const GamesRoutes = {
    path: '/',
    component: GamesModule,
    children: [
        {
            path: 'live-casino',
            name: 'live-casino',
            component: LiveCasino
        },
        {
            path: 'indian-card-games',
            name: 'indian-card-games',
            component: LiveCasino
        },
        {
            path: 'slot-games',
            name: 'slot-games',
            component: LiveCasino
        },
        {
            path: 'multiplayer-games',
            name: 'multiplayer-games',
            component: LiveCasino
        },
        {
            path: 'fishing-games',
            name: 'fishing-games',
            component: LiveCasino
        },
        {
            path: 'home-section',
            name: 'home-section',
            component: LiveCasino
        },
        {
            path: 'evolution',
            name: 'evolution',
            component: LiveCasino
        },
        {
            path: 'royal-gaming',
            name: 'royal-gaming',
            component: LiveCasino
        },
        // {
        //     path: 'slot-games',
        //     name: 'slot-games',
        //     component: SlotGames
        // },
        // {
        //     path: 'indian-card-games',
        //     name: 'indian-card-games',
        //     component: IndianCardGames
        // }

    ],
}

export default GamesRoutes;