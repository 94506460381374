<template>
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="betslip-affix-box">
            <div class="betslip-sec">
                <h3>Betslip</h3>
            </div>
            <div class="edits-stack-sec">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                        <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#betslip-sec"
                            type="button" role="tab" aria-controls="nav-home" aria-selected="true"> Betslip </button>
                        <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#open-bet-sec"
                            type="button" role="tab" aria-controls="nav-profile" aria-selected="false"> Open Bets </button>
                        
                    </div>
                    <button class="nav-link eidt-stac" id="nav-contact-tab" @click="showEditStake=true" type="button" role="tab"> Edit Stakes </button>
                </nav>
                <div class="tab-content" id="nav-tabContent" v-if="betData">
                    <div class="tab-pane fade show" :class="betData.bet_slip ? 'active' :''" id="betslip-sec" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="pages-text">
                            <div class="bookmaker-sec betslip-wrapper-card">
                                <div class="bet-slip-body-sec back-betslip-active">
                                    <div class="match-schudle">
                                        <div class="match-team-name">
                                            <div class="match-team">
                                                <div class="top-bl-mark"><span class="lay-betslip-active mark-back"></span>
                                                    {{ betData.selection_type }}</div>
                                                <p>19:30 {{ betData.event_name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fancy-slip-box-sec">
                                        <div class="ipbet-name">
                                            <span>{{ betData.runner_name }}</span>
                                            <span>Max Market: 0</span>
                                        </div>
                                        <div class="bs-odds-stake-box">
                                            <ul class="cmn-ul-list">
                                                <li>
                                                    <div class="mv-odds">
                                                        <label>Odds</label>
                                                        <div class="stake-profit-bx">
                                                            <div class="stake-btn">
                                                                <div class="input-group plus-minus-input">
                                                                    <span class="input-group-btn">
                                                                        <button
                                                                            class="btn cmn-btn value-control minus-btn"
                                                                            data-action="minus" data-target="font-size">
                                                                            <i class="fa-solid fa-minus"></i>            
                                                                        </button>
                                                                    </span>
                                                                    <input type="text" :value="betData.odds" disabled class="form-control"
                                                                        id="font-size" placeholder="Stake">
                                                                    <span class="input-group-btn">
                                                                        <button
                                                                            class="btn cmn-btn value-control plus-btn"
                                                                            data-action="plus" data-target="font-size">
                                                                            <i class="fa-solid fa-plus"></i>
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-stakes">
                                                        <label>Stake</label>
                                                        <input v-model="current_bet.stake" type="number" class="form-control" placeholder="0">
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-profit">
                                                        <label>Profit</label>
                                                        <p>0</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-close">
                                                        <button class="btn bs-delete-btn"><i
                                                                class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="choose-rate-box">
                                            <ul class="cmn-ul-list">
                                                <li><button class="btn bet-rate-btn">+300</button></li>
                                                <li><button class="btn bet-rate-btn">+500</button></li>
                                                <li><button class="btn bet-rate-btn">+1,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">Max</button></li>
                                                <li><button class="btn bet-rate-btn">+5,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+10,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+25,000</button>
                                                </li>
                                                <li><button class="btn link-btn">Clear</button></li>
                                            </ul>
                                        </div>
                                        <div class="place-and-remove-bet-sec">
                                            <div class="remove-bet">
                                                <button type="button" id="remove-btn1">Remove
                                                    all</button>
                                            </div>
                                            <div class="place-bet">
                                                <button type="button" @click="saveBets()">Place bet</button>
                                            </div>
                                        </div>
                                        <div class="form-check click-box">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                                checked>
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Confirm bet before placing
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="bet-slip-body-sec lay-betslip-active d-none">
                                    <div class="match-schudle">
                                        <div class="match-team-name">
                                            <div class="match-team">
                                                <div class="top-bl-mark"><span class="mark-back"></span>
                                                    Lay</div>
                                                <p>19:30 Lucknow Super Giants v Sunrisers Hyderabad</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fancy-slip-box-sec">
                                        <div class="ipbet-name"><span>Lucknow Super
                                                Giants</span><span>Max Market: 0</span>
                                        </div>
                                        <div class="bs-odds-stake-box">
                                            <ul class="cmn-ul-list">
                                                <li>
                                                    <div class="mv-odds">
                                                        <label>Odds</label>
                                                        <div class="stake-profit-bx">
                                                            <div class="stake-btn">
                                                                <div class="input-group plus-minus-input">
                                                                    <span class="input-group-btn"><button
                                                                            class="btn cmn-btn value-control minus-btn"
                                                                            data-action="minus" data-target="font-size"><i
                                                                                class="fa-solid fa-minus"></i></button></span>
                                                                    <input type="text" value="1" class="form-control"
                                                                        id="font-size" placeholder="Stake">
                                                                    <span class="input-group-btn"><button
                                                                            class="btn cmn-btn value-control plus-btn"
                                                                            data-action="plus" data-target="font-size"><i
                                                                                class="fa-solid fa-plus"></i></button></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-stakes">
                                                        <label>Stake</label>
                                                        <input type="text" value="" class="form-control" placeholder="0">
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-profit">
                                                        <label>Profit</label>
                                                        <p>0</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="mv-close">
                                                        <button class="btn bs-delete-btn"><i
                                                                class="fa-solid fa-xmark"></i></button>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="choose-rate-box">
                                            <ul class="cmn-ul-list">
                                                <li><button class="btn bet-rate-btn">+300</button></li>
                                                <li><button class="btn bet-rate-btn">+500</button></li>
                                                <li><button class="btn bet-rate-btn">+1,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">Max</button></li>
                                                <li><button class="btn bet-rate-btn">+5,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+10,000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+25,000</button>
                                                </li>
                                                <li><button class="btn link-btn">Clear</button></li>
                                            </ul>
                                        </div>
                                        <div class="place-and-remove-bet-sec">
                                            <div class="remove-bet">
                                                <button type="button" id="remove-btn1">Remove
                                                    all</button>
                                            </div>
                                            <div class="place-bet">
                                                <button type="button">Place bet</button>
                                            </div>
                                        </div>
                                        <div class="form-check click-box">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                                checked>
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Confirm bet before placing
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="bookmaker" id="lay-bet">
                                <div class="bet-slip-body-sec">
                                    <div class="match-schudle">
                                        <div class="match-team-name">
                                            <div class="match-team">
                                                <span>Lay</span>
                                                <p>New Zealand v England</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="fancy-slip-box-sec open-bet-slip-sec">
                                        <div class="stake-profit-box">
                                            <div class="odds">
                                                <label>ODDS:</label>
                                                <input type="text" value="" class="form-control" placeholder="3.65">
                                            </div>
                                            <div class="stakes">
                                                <label>STAKE</label>
                                                <input type="text" value="" class="form-control" placeholder="0">
                                            </div>
                                        </div>
                                        <div class="choose-rate-box">
                                            <ul class="cmn-ul-list">
                                                <li><button class="btn bet-rate-btn">+100</button></li>
                                                <li><button class="btn bet-rate-btn">+500</button></li>
                                                <li><button class="btn bet-rate-btn">+1000</button></li>
                                                <li><button class="btn bet-rate-btn">+2000</button></li>
                                                <li><button class="btn bet-rate-btn">+5000</button></li>
                                                <li><button class="btn bet-rate-btn">+10000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+25000</button>
                                                </li>
                                                <li><button class="btn bet-rate-btn">+50000</button>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="place-and-remove-bet-sec">
                                            <div class="remove-bet">
                                                <button type="button" id="remove-btn2">Remove
                                                    all</button>
                                            </div>
                                            <div class="place-bet">
                                                <button type="button">Place bet</button>
                                            </div>
                                        </div>
                                        <div class="form-check click-box">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked"
                                                checked>
                                            <label class="form-check-label" for="flexCheckChecked">
                                                Confirm bet before placing
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white p-2" v-if="!betData.bet_slip">
                        <p>Click on the odds to add selections to the betslip</p>
                    </div>
                    <div class="tab-pane fade show active" id="betslip-sec" role="tabpanel" aria-labelledby="nav-home-tab"></div>
                    <div class="tab-pane fade open-bet-tab" id="open-bet-sec" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <div class="pages-text open-bet-content">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    Show bet Info
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                                <label class="form-check-label" for="flexCheckChecked">
                                    Show bets for this event
                                </label>
                            </div>
                        </div>
                    </div>
                    
                    <StakeModal v-if="showEditStake" :showEditStake="showEditStake" @show-edit-stake-false="showEditStake=false" />

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import StakeModal from './StakeModal.vue';

export default{
    name:'BetSlip',
    props:['betData'],
    data(){
        return{
            current_bet:{
                stake:'',
                bet_user_guid:'',
            }

        }
    },
    methods:{
        saveBets(){
            this.betData['stake'] = this.current_bet.stake
            this.betData['validate'] = 1
            this.betData['accept_any_odds'] = true

            this.loading = true;
            api.post(apiName.SAVE_BETS, this.betData).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        this.betData['bet_user_guid'] = crypto.randomUUID();
                        this.betData['validate'] = 0
                        setTimeout(() => { 
                            this.loading = true;
                            api.post(apiName.SAVE_BETS, this.betData).then(response => {
                                this.loading = false;
                                if (response) {
                                    if (response.status == 200) {
                                        ("socnd bet place", response);
                                        this.betSlip_Modal.hide()
                                        this.showSuccessModalFunc(response.data.message)
                                    }
                                }
                            }).catch(error => {
                                this.loading = false;
                                if (error) {
                                    console.log("Error : ", error);
                                    this.showErrorModalFunc(error?.data?.message);
                                }
                            });
                         }, response.data.data.bet_delay * 1000)
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    this.showErrorModalFunc(error[0]);;
                }
            });
        },

    }
}

</script>

<style scoped>

.display_block{
    display: block !important;
}

.display_none{
    display: none !important;
}

</style>