<template>
    <div class="modal fade Betamount" id="clickBetValues" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>{{translatedLangData('click-bet-value', 'Click Bet Value')}}</h5>
                    <button type="button" class="btn-close" aria-label="Close" @click="closeOneClickModel()">
                    </button>
                </div>
                <div class="modal-body">
                    <div class="stake_oneclick_bet">
                        <ul v-if="inputvalue">
                            <li><input type="tel" @click="selectInputValue(0)" :class="{ 'border-input': inputvalue[0].isSelected }"
                                    v-model="inputvalue[0].value" class="btn-thm m-auto" @input="isNumber($event, 0),checkLimit(0)">
                            </li>
                            <li><input type="tel" @click="selectInputValue(1)" :class="{ 'border-input': inputvalue[1].isSelected }"
                                    v-model="inputvalue[1].value" class="btn-thm m-auto" @input="isNumber($event, 1),checkLimit(1)">
                            </li>
                            <li><input type="tel" @click="selectInputValue(2)" :class="{ 'border-input': inputvalue[2].isSelected }"
                                    v-model="inputvalue[2].value" class="btn-thm m-auto"  @input="isNumber($event, 2),checkLimit(2)">
                            </li>
                            <li><input type="tel" @click="selectInputValue(3)" :class="{ 'border-input': inputvalue[3].isSelected }"
                                    v-model="inputvalue[3].value" class="btn-thm m-auto"  @input="isNumber($event, 3),checkLimit(3)">
                            </li>
                        </ul>
                    </div>
                    <div class="min-max-section">
                        <div class="min-max-con">
                            <h6>{{translatedLangData('min', 'min')}} <span>100.00</span></h6>
                        </div>
                        <div class="min-max-icon">
                            <img src="@/assets/images/min-max-icon.webp" alt="">
                        </div>
                        <div class="min-max-con">
                            <h6>{{translatedLangData('max', 'max')}} <span>5,000.00</span></h6>
                        </div>
                    </div>
                    <div class="header-but  info-footer edit-stake-btn">
                        <button type="button" class="thm-but thm-bor-but cancel-btn" @click="closeOneClickModel()">{{translatedLangData('cancel', 'Cancel')}}</button>
                        <a @click="saveClickBetValue()" class="thm-but ">{{translatedLangData('save', 'Save')}}</a>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    name: "ClickBetModal",
    inject: ['translatedLangData'],
    data() {
        return {
            inputvalue: null,
            oneCLickModal: null
        }
    },
    mounted() {
        this.fillInputValues()
        this.oneCLickModal = new Modal('#clickBetValues');
    },
    methods: {
        isNumber(evt, index) {
            let newValue = evt.target.value;
            newValue = newValue.replace(/\D/g, ''); // Remove all non-numeric characters
            if (newValue.length === 1 && newValue === '0') {
                this.inputvalue[index].value = '';
                evt.target.value = '';
            } else if (evt.data == '0' && newValue.startsWith('0')) {
                evt.target.value = Number(this.inputvalue[index].value);
            } else if (/^\d+$/.test(newValue)) {
                this.inputvalue[index].value = Number(newValue);
            } else {
                if (newValue !== '') {
                    evt.target.value = this.inputvalue[index].value !== undefined ? this.inputvalue[index].value : '';
                } else {
                    this.inputvalue[index].value = '';
                }
            }
        },
        checkDuplicates(){
            const dupValues = this.inputvalue.map(obj => obj.value);
            const uniqueValues = new Set(dupValues);
            return uniqueValues.size !== dupValues.length;
        },
        fillInputValues(){
            const clickBetValue = this.getClickBetValue();
            if (clickBetValue) {
                this.inputvalue = JSON.parse(JSON.stringify(clickBetValue));
            }
            else {
                this.inputvalue = [
                    {
                        value: 1000,
                        isSelected: true,
                    },
                    {
                        value: 2000,
                        isSelected: false,
                    },
                    {
                        value: 3000,
                        isSelected: false,
                    },
                    {
                        value: 4000,
                        isSelected: false,
                    }
                ]
            }
        },
        getClickBetValue() {
            return this.$store.getters.clickBetValue ? JSON.parse(JSON.stringify(this.$store.getters.clickBetValue)) : null;
        },
        selectInputValue(index) {
            for (let i = 0; i < this.inputvalue.length; i++) {
                this.inputvalue[i].isSelected = false;
            }
            this.inputvalue[index].isSelected = true;
        },
        saveClickBetValue() {            
            for(let e of this.inputvalue){
                if(!(e.value <= 5000 && e.value >= 100)){
                    this.showErrorModalFunc('One Click Bet Values Should Be Between 100 And 5000.');  
                    return;             
                }
            }
            if(this.checkDuplicates()){
                this.showErrorModalFunc('Duplicate One Click Bet Values Not Allowed');
                return;
            }
            
            else{
                this.$store.dispatch('setClickBetValue', this.inputvalue);
                this.$store.dispatch('setIsClickBetOn', true);
                this.showSuccessModalFunc('One Click Bet is active.');
                this.closeOneClickModel(false);
            }
        },
        checkLimit(index) {
            let inpValue = this.inputvalue[index]
            // if(!(inpValue.value <= 5000 && inpValue.value >= 100)) {
            //     this.showErrorModalFunc('One Click values should be between 100 and 5000.');
            // }
            inpValue.value = Math.max(0, Math.min(5000, inpValue.value));
        },

        closeOneClickModel(cancelOneclick = true) { 
            this.fillInputValues()          
            if(cancelOneclick) {
                this.$emit('cancelOneClick');
            }
            this.oneCLickModal.hide();
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        },

    }
};
</script>
<style scoped>

</style>