<template>
    <div class="modal fade game-point-note-modal" id="offer_pop_up" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="border: none;">
                <div class="modal-body">
                    <section class="sub-header-wrapper">
                        <div class="gp-content" v-if="siteSettings?.offer?.image">
                            <img data-bs-dismiss="modal" @click="closeOffer" class="gp-closeButton"
                                src="@/assets/images/closeButton.svg" alt="">
                            <a href="javascript:void(0)">
                                <img :src="siteSettings.aws_url + siteSettings.storage_path.offer_path + siteSettings.offer?.image"
                                    class="offer-img" alt="">
                            </a>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            siteSettings: 'siteSettings',
        }),
    },
    mounted(){
        setTimeout(()=>{
            this.closeOffer()
        },2000)
    },    
    methods: {
        closeOffer() {
            localStorage.setItem('is_offer', false);
        }
    }
}
</script>
<style scoped>
.offer-img {
    width: 100%;
    height: auto;
}

.gp-content {
    position: relative;
    width: 100%;
    height: auto;
    padding: 0 0 0px;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .25), inset -3px 4px 6px rgba(0, 0, 0, .25);
    border-radius: 30px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
</style>