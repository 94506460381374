const PoliciesModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const Policies = () => import(/* webpackChunkName: "my-market" */ './views/Policies.vue');

const PoliciesRoutes = {
    path: '/',
    component: PoliciesModule,
    children: [
        {
            path: 'policies/:type',
            name: 'policies',
            component: Policies
        }
    ],
}

export default PoliciesRoutes;